import { render, staticRenderFns } from "./snackbarItem.vue?vue&type=template&id=bfec342a&scoped=true"
import script from "./snackbarItem.vue?vue&type=script&lang=js"
export * from "./snackbarItem.vue?vue&type=script&lang=js"
import style0 from "./snackbarItem.vue?vue&type=style&index=0&id=bfec342a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfec342a",
  null
  
)

export default component.exports