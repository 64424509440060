export default {
    data() {
        return {
            bankingProgramsSort: []
        }
    },
    methods: {
        getBankingProgramsSort() {
            const programs = this.bankingPrograms.reduce((acc, el) => {
                const item = structuredClone(el)
                const bankId = item.bank?.id ?? 0
                const bank = this.bankingProgramsSort.find(item => item.id === bankId) ?? true
                if(!acc[bankId]) {
                    acc = {
                        ...acc,
                        [bankId]: {
                            id: bankId,
                            name: item.bank?.name ?? '',
                            visibleChildren: bank && bank.visibleChildren != undefined ? bank.visibleChildren : true,
                            children: []
                        }
                    }
                }
                delete item.bank
                acc[bankId].children.push(item)
                return acc
            }, {})
            this.bankingProgramsSort = Object.values(programs)
        },
    }
}