<script>
export default {
  name: "bankCard",
  props: {
    icon: {
      type: String
    },
    text: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    },
    stretched: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
<div :class="[{active}, {stretched},'bank-card']">
  <img v-if="icon" :src="icon" alt="">
  <template v-if="text">{{$t(text)}}</template>
</div>
</template>

<style scoped lang="scss">
.bank-card {
  &.stretched {
    flex: 1;
  }
  background: var(--colorBackgroundPrimary);
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  min-height: 78px;
  width: 146px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #6F77FE;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  border: 1px solid transparent;
  transition: all 0.2s ease;
  > img {
    filter: grayscale(1);
    padding: 4px;
    width: 100%;
    height: 30px;
  }
  &:hover {
    > img {
      filter: grayscale(0);
    }
    box-shadow: 0 8px 16px 0 rgba(23, 23, 28, 0.05), 0 0 8px 0 rgba(30, 30, 36, 0.10);
  }
  &.active {
    > img {
      filter: grayscale(0);
    }
    border: 1px solid var(--colorStrokeAccentInvariably);
    background: var(--colorBackgroundContrastInvariably);
  }
}
</style>