<script>
import axios from "axios";
import SearchComponent from "@/components/redesign/searchComponent.vue";
import SegmentedControl from "@/components/redesign/segmentedControl.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import priceFormat from "../../../mixins/priceFormat";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import FormItem from "@/components/redesign/FormItem.vue";
import DefaultNewInput from "@/components/redesign/defaultNewInput.vue";
import FromMediaUploader from "@/components/redesign/FromMediaUploader.vue";
import DefaultPopup from "@/components/redesign/defaultPopup.vue";
import ActionSheetComponent from "@/components/redesign/actionSheetComponent.vue";
import BankCard from "@/components/redesign/calculatorComponents/bankCard.vue";
import AlertModal from "@/components/redesign/alertModal.vue";
import TableComponent from "@/components/redesign/Table/tableComponent.vue";
import FunctionsTable from "@/components/redesign/Table/FunctionsTable.vue";
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import Loader from "@/components/Loader.vue";
import TableRowChips from "@/components/redesign/Table/tableRowChips.vue";
import InfiniteLoader from "@/components/redesign/infiniteLoader.vue";
import bankingPrograms from "@/mixins/calculator/bankingPrograms";
export default {
  name: "BankingProgramsLayout",
  components: {
    InfiniteLoader,
    TableRowChips,
    Loader,
    OptionsRow,
    FunctionsTable,
    TableComponent,
    AlertModal,
    BankCard,
    ActionSheetComponent,
    DefaultPopup,
    FromMediaUploader,
    DefaultNewInput,
    FormItem,
    ModalContainer, DefaultButton, IconComponent, SegmentedControl, SearchComponent},
  mixins: [priceFormat, bankingPrograms],
  data() {
    return {
      search: null,
      page: 1,
      pageCount: null,
      bank: {
        name: '',
        site: '',
        avatar: '', //?
        logo: '', //?
        // logo_monochrome: '', // ?
      },
      banksList: [],
      visibleRequiredFields: false,
      visibleModalCreateBank: false,
      visibleModalBanks: false,
      visibleConfirmArchivePrograms: false,
      visibleConfirmArchiveProgram: false,
      bankIdArchivePrograms: null,
      bankingPrograms: [],
      // bankingProgramsSort: [],
      activeTab: {},
      tabs: [
        {
          value: this.$i18n.t('Актуальное'),
          id: 'active',
          icon: '/default-icons/task.svg',
          action: 'archive'
        },
        {
          value: this.$i18n.t('Архив'),
          id: 'archive',
          icon: '/default-icons/history.svg',
          action: 'active'
        }
      ],
      isLoading: false
    }
  },
  watch: {
    page() {
      if(this.page !== 1) {
        this.getPrograms()
      }
    },
    search() {
      this.resetPage()
    },
    bankingPrograms(value) {
      this.getBankingProgramsSort()
    },
    visibleModalCreateBank(value) {
      if(!value) {
        this.bank = this.defaultBankField
      }
    },
    activeTab: {
      handler(after) {
        this.resetPage()
      },
      deep: true
    }
  },
  computed: {
    checkEmptyRequiredFields() {
      return !this.bank.name ||
          !this.bank.site ||
          !this.bank.avatar ||
          !this.bank.logo
          // !this.bank.logo_monochrome;
    },
    defaultBankField() {
      return {
        name: '',
        site: '',
        avatar: '',
        logo: '',
        // logo_monochrome: '',
      }
    }
  },
  methods: {
    resetPage() {
      this.page = 1
      this.bankingPrograms = []
      this.pageCount = null

      this.getPrograms()
    },
    actionProgramsByBank() {

    },
    programsInBank(bankId) {
      const bank = this.bankingProgramsSort.find(el => el.id == bankId)
      return !!(bank && bank.children && bank.children.length);
    },
    getText(values, text) {
      for (const value of Object.values(values)) {
        if(!value && value != 0) {
          return null
        }
      }
      return text
    },
    async actionProgram() {
      const res = await axios.get(`calculator/programs/${this.activeTab.action}?id=` + this.program.id)
          .then(res => res.data)
          .catch(e => {})

      this.visibleConfirmArchiveProgram = false
      this.resetPage()
    },
    confirmActionProgram(program) {
      this.program = program

      if(this.activeTab.id === 'archive') {
        this.actionProgram()
        return;
      }

      this.visibleConfirmArchiveProgram = true
    },
    editProgram(program) {
      this.$router.push({name: 'edit-program', params: {id: program.id}})
    },
    saveBank() {
      if(this.bank.id) {
        return this.changeBank()
      }
      return this.createBank()
    },
    editBank(bank) {
      this.bank = {
        id: bank.id,
        name: bank.name,
        site: bank.site,
        avatar: bank.avatar,
        logo: bank.logo,
        // logo_monochrome: bank.logo_monochrome,
      }
      this.visibleModalCreateBank = true
    },
    toCreateProgram() {
      this.$router.push({name: 'create-program'})
    },
    async changeBank() {
      if(this.checkEmptyRequiredFields) {
        this.visibleRequiredFields = true
        return;
      }
      const res = await axios.post('/api/post-bank?id='+this.bank.id, this.bank)
          .then(res => res.data)
          .catch(e => null)

      this.visibleModalCreateBank = false
    },
    async getBanks() {
      const res = await axios.get('api/get-banks')
          .then(res => res.data)
          .catch(e => [])

      this.banksList = res
    },
    async createBank() {
      if(this.checkEmptyRequiredFields) {
        this.visibleRequiredFields = true
        return;
      }

      const bankId = await axios.post('/api/create-bank')
          .then(res => res.data)
          .catch(e => null)

      if(bankId == null) {
        return;
      }

      const res = await axios.post('/api/post-bank?id='+bankId, this.bank)
          .then(res => res.data)
          .catch(e => null)
      this.visibleModalCreateBank = false

    },
    async getPrograms() {
      this.isLoading = true
      const res = await axios.get('calculator/programs/get-all-with-filter', {
        params: {
          mode: this.activeTab?.id,
          search: this.search,
          page: this.page
        }
      })
          .then(res => res.data)
          .catch(e => [])

      this.pageCount = res?.pageCount
      this.bankingPrograms.push(...(res.data ?? []))
      this.isLoading = false
    },
    confirmArchiveBankPrograms(bankId) {
      if(!this.programsInBank(bankId)) {
        return;
      }

      this.visibleConfirmArchivePrograms = true
      this.visibleModalBanks = false
      this.bankIdArchivePrograms = bankId
    },
    async archiveBankPrograms() {
      const res = await axios.get('calculator/programs/archive-by-bank?id=' + this.bankIdArchivePrograms)
          .then(res => res.data)
          .catch(e => null)

      if(res.status === true) {
        this.getBanks()
        this.resetPage()
      }
      this.cancelArchiveBank()
    },
    cancelArchiveBank() {
      this.visibleConfirmArchivePrograms = false
      this.visibleModalBanks = true
    }
  },
  mounted() {
    this.activeTab = this.tabs[0]
    this.getBanks()
    // this.getPrograms()
  },

}
</script>

<template>
  <div class="banking-programs">
    <alert-modal
      v-if="visibleConfirmArchivePrograms"
      :centralText="true"
      :title="$t('ОтключениеБанка')"
      description="Вы уверены что хотите отключить этот банк?
Весь раздел с программами банка перейдет в архив."
      :confirmText="$t('Отключить')"
      :visibleCancelBottomButton="true"
      @confirm-alert="archiveBankPrograms"
      @cancel-alert="cancelArchiveBank"
    >
    </alert-modal>
    <alert-modal
        v-if="visibleConfirmArchiveProgram"
        :centralText="true"
        :title="$t('ОтключениеПрограммы')"
        description="Вы уверены что хотите отключить эту программу"
        :confirmText="$t('Отключить')"
        :visibleCancelBottomButton="true"
        @confirm-alert="actionProgram"
        @cancel-alert="visibleConfirmArchiveProgram = false"
    />
    <modal-container
        v-if="visibleModalCreateBank"
        :title="bank.id ? 'ИзменениеБанка' : 'СозданиеБанка'"
        :confirmText="'Применить'"
        :width="'464px'"
        @close-modal="visibleModalCreateBank = false"
        @confirm-option="saveBank"
    >
      <form-item
          :title="'Название'"
          :required="true"
          :notificationError="visibleRequiredFields && !bank.name"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <default-new-input
            :notificationError="visibleRequiredFields && !bank.name"
            v-model="bank.name"
            placeholder="Любое"/>
      </form-item>
      <form-item
          :title="'СсылкаНаСайт'"
          :required="true"
          :notificationError="visibleRequiredFields && !bank.site"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <default-new-input
            :notificationError="visibleRequiredFields && !bank.site"
            v-model="bank.site"
            placeholder="Любое"
        />
      </form-item>
      <form-item
          :title="'Аватар'"
          :required="true"
          :notificationError="visibleRequiredFields && !bank.avatar"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <from-media-uploader v-model="bank.avatar"/>
      </form-item>
      <form-item
          :title="'ОсновнаяВерсияЛоготипа'"
          :required="true"
          :notificationError="visibleRequiredFields && !bank.logo"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <from-media-uploader v-model="bank.logo"/>
      </form-item>
<!--      <form-item
          :title="'МонохромнаяВерсияЛоготипа'"
          :required="true"
          :notificationError="visibleRequiredFields && !bank.logo_monochrome"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <from-media-uploader v-model="bank.logo_monochrome"/>
      </form-item>-->
    </modal-container>

    <modal-container
        v-if="visibleModalBanks"
        :title="'Банки'"
        :subtitle="'СписокВсехБанковПлатформы'"
        :confirmText="'Применить'"
        width="510px"
        @close-modal="visibleModalBanks = false"
        @confirm-option="actionProgramsByBank"
        class-component="modal-banks"
    >
      <template v-slot:headers-right>
        <div @click="visibleModalCreateBank = true">
          <icon-component
              :icon="'/default-icons/add_circle.svg'"
              :color="'var(--colorIconAccent)'"
              :size="24"
          />
        </div>
        <default-button
            type="tertiary"
            @click-element=""
            size="medium"
            width="fit-content"
        >{{$t('Ещё?')}}</default-button>
      </template>
      <div class="content">
        <bank-card
            v-for="bank of banksList"
            :icon="bank.logo"
            :active="programsInBank(bank.id)"
            @click.native="confirmArchiveBankPrograms(bank.id)"
        />
      </div>

    </modal-container>
    <functions-table
      :tabs="tabs"
      @update:activeTab="(value) => activeTab = value"
      @update:searchQuery="(value) => search = value"
      @add-row="toCreateProgram"
    >
      <template #content>
        <default-button
            @click-element="visibleModalBanks = true"
            type="tertiary"
            size="medium"
            leftIcon="/default-icons/bank.svg"
        >{{$t('Банки')}}</default-button>
      </template>
    </functions-table>

    <table-component
        v-if="bankingProgramsSort.length"
      v-model="bankingProgramsSort"
      :colspan="10"
    >
      <template #header-table>
        <th>ID</th>
        <th>old_id_calc_program</th>
        <th>old_id_program</th>
        <th>Название</th>
        <th>Субсидирование</th>
        <th>Основная ставка</th>
        <th>Ставка</th>
        <th>ПВ</th>
        <th>Лимит кредита</th>
        <th>Срок кредита</th>
      </template>
      <template #title-options="{ el }">
        <default-button
            v-if="el.id != 0"
            @click-element="editBank(el)"
            type="outline"
            size="medium"
            leftIcon="/default-icons/info_fill.svg"
        />
      </template>
      <template #content-table-title="{ el }">
        {{el.name}}
      </template>
      <template #content-table-child="{child}">
        <td>{{child.id}}</td>
        <td>{{child.old_calc_program_id}}</td>
        <td>{{child.old_program_id}}</td>
        <td>{{child.name}}</td>
        <td>
          <icon-component
              :icon="child.subsidy == 1  ? '/default-icons/done.svg' : '/default-icons/zoom_out.svg'"
              :color="child.subsidy == 1 ? 'var(--colorIconAccent)' : 'var(--colorIconSecondary)'"
              :size="24"/>
        </td>
        <td>{{getText([child.total_rate], `${child.total_rate}%`)}}</td>
        <td>{{getText([child.subsidy_rate], `${child.subsidy_rate}%`)}}</td>
        <td>{{getText(child.pv, `${child.pv?.min}% - ${child.pv?.max}%`)}}</td>
        <td>{{getText(child.sum_credit, `${priceFormat(child.sum_credit?.min)} - ${priceFormat(child.sum_credit?.max)}`)}}</td>
        <td>{{getText(child.time_credit, `${child.time_credit?.min} мес - ${child.time_credit?.max} мес`)}}</td>
        <td class="options">
          <options-row
              :activeTab="activeTab?.id"
              @read-more="editProgram(child)"
              @archive="confirmActionProgram(child)"
          />
        </td>
      </template>
    </table-component>
    <infinite-loader
        :is-loading="isLoading"
        :page-count="pageCount"
        :page="page"
        @change-page="(value) => page = value"
    >
    </infinite-loader>
  </div>
</template>

<style scoped lang="scss">
.banking-programs {

}
.modal-banks {
  .content {
    justify-content: center;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}
</style>