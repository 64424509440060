<template>
  <div class="navigation-bar">
    <div class="navigation"> <!--Меню слева (на мобилке переходит вниз экрана в нав бар)-->
      <div class="nav-logo-wrapper">
        <router-link :to="'/'" tag="a" class="nav-logo-container">
          <img class="nav-logo-container__picture" :src="site.logo ?? '/itrielt.svg'" alt="logo ITRIELT" />
        </router-link>
      </div>
      <template  v-for="category in categories">
        <div
            v-if="!isEmptyItems(category.slug) || !isEmpty(category.path)"
            :key="category.slug"
            class="nav-item"
            :class="[
          {
            'active': !visibleOptionsMenu&&!visibleExitAlert&& (activeNavItem === category.slug || (changeActiveCategory === category.slug && !activeNavItem)),
          },
          category.slug,
        ]"
            @click="openCategory(category)"
        >
          <div class="nav-indicator" v-if="false">
            <indicator-badge/>
          </div>
          <div
              class="nav-icon"
              :style="'mask: url(' + category.iconSrc + ') no-repeat center / contain;'"
          ></div>
          <span class="nav-label">{{ $t(category.label || "") }}</span>
        </div>
      </template>
      <div class="nav-separator"></div>
      <div
          v-if="showMyLink"
          class="nav-item support"
          @click="clickSupport"
      >
        <div
          class="nav-icon"
          :style="'mask: url(/default-icons/support_fill.svg) no-repeat center / contain;'"
        ></div>
        <span class="nav-label">{{ $t("Поддержка") }}</span>
        <div class="chat-indicator-message">
          <indicator-counter
            v-if="$root.chatIndicatorValue"
            type="contrast"
            size="medium"
            appearance="negative"
            :counter="$root.chatIndicatorValue"
          />
        </div>
      </div>
      <div
          class="nav-item"
          :class="{'active':visibleOptionsMenu||visibleExitAlert}"
          @click="toggleVisibilityOptionsMenu"
      >
        <div class="nav-avatar">
          <img
              class="nav-avatar__el"
              :src="profile.avatar??'/my/noavatar.png'"
              alt="photo"
          />
        </div>
        <span class="nav-label">{{ $t("Меню") }}</span>
      </div>
    </div>
    <transition name="navigate"> <!--На компах это плитка, на мобилке списком. Отображаются все страницы в категории-->
      <div v-if="!isEmptyItems(activeNavItem)" class="modal-list-pages-wrapper" :key="activeNavItem">
        <div class="modal-list-pages" @click.self="closeListPages" v-on-clickaway="closeListPages">
          <div class="navigation-tiles" @click.self="closeListPages">
            <div
                class="nav-tile"
                v-for="route in routes"
                :key="route.path"
                @click="closeCategory(route.path)"
                @click.middle="openItemNewTab(route.path)"
            >
              <div
                  class="tile-icon"
                  :style="
              'mask: url(/default-icons/' +
              route.meta.icon +
              '.svg) no-repeat center / contain;'
            "
              ></div>
              <div class="tile-info">{{ $t(route.meta.label || "") }}</div>
            </div>
          </div>
          <div class="navigation-tiles-mobile">
            <default-header
                @on-go-back-button-click="closeListPages('dynamic')"
                :title="$t(currentCategoryName || '')"
                :type-icon-back="activeNavItem!=='clients'&&activeNavItem!=='objects'?'return':'close'"
                :style="{borderRadius: '12px 12px 0 0'}"
                grid="plain"
            />
            <div class="navigation-tiles-mobile__body">
              <div class="body-modal-padding">
                <div
                    class="nav-tile"
                    v-for="route in routes"
                    :key="route.path"
                    @click="closeCategory(route.path)"
                    @click.middle="openItemNewTab(route.path)"
                >
                  <div class="tile-icon">
                    <icon-component
                        :icon="`/default-icons/${route.meta.icon}.svg`"
                        :color="'var(--colorIconAccent)'"
                        :size="24"
                    />
                  </div>
                  <div class="tile-info">{{ $t(route.meta.label || "") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="navigate"> <!--Доп меню-->
      <div class="options-menu-wrapper" v-if="visibleOptionsMenu" key="options-menu">
        <div class="options-menu-container" v-on-clickaway="hideOptionsMenu">
          <div class="options-menu-profile">
            <div class="options-menu-header">
              <div class="options-header-icon">
                <div class="header-logo">
                  <img class="header-logo__picture" :src="site.logo ?? '/itrielt.svg'" alt="logo ITRIELT" />
                </div>
              </div>
              <div class="options-header-content">
                <img
                    class="options-header-content__avatar"
                    :src="profile.avatar??'/my/noavatar.png'"
                    alt="photo"
                />
              </div>
              <div class="options-header-icon">
                <icon-component
                    :icon="'/default-icons/close.svg'"
                    :color="'var(--colorIconAccent)'"
                    :size="24"
                    :clickability="true"
                    @click-element="hideOptionsMenu"
                />
              </div>
            </div>
            <div class="options-menu-info" v-if="infoUser||infoCompany">
              <div class="options-menu-info__title" v-if="infoUser">{{infoUser}}</div>
              <div class="options-menu-info__description" v-if="infoCompany">{{infoCompany}}</div>
            </div>
            <div class="options-menu-link-profile">
              <default-button
                  size="small"
                  type="tertiary"
                  width="auto"
                  :right-icon="'/default-icons/navigate_next.svg'"
                  @click-element="closeCategory('/profile')"
                  @click.native.middle="openItemNewTab('/profile')"
              >{{ $t("ЛичныеДанные") }}</default-button>
            </div>
          </div>
          <div class="options-menu-body">
            <div class="cell-option" @click="clickMyLink" v-if="showMyLink">
              <div class="cell-option__left">
                <icon-component
                    :icon="'/default-icons/qr_code.svg'"
                    :color="'var(--colorIconAccent)'"
                    :size="24"
                />
              </div>
              <div class="cell-option__middle">{{ $t("МояСсылка") }}</div>
            </div>
            <div class="cell-option"
                 v-for="route in getRoutes('additional')"
                 @click="closeCategory(route.path)"
                 @click.middle="openItemNewTab(route.path)"
                 :key="route.path"
            >
              <div class="cell-option__left">
                <icon-component
                    :icon="`/default-icons/${route.meta.icon}.svg`"
                    :color="'var(--colorIconAccent)'"
                    :size="24"
                />
              </div>
              <div class="cell-option__middle">{{$t(route.meta.label || "")}}</div>
              <div class="cell-option__right" v-if="coinsVisible&&route.name==='shop'">
                <div class="chip-coin">
                  <div class="chip-coin__content">
                    <icon-component
                        :icon="'/default-icons/coin.svg'"
                        :color="'var(--colorIconContrastInvariably)'"
                        :size="16"
                    />
                  </div>
                  <div class="chip-coin__label">{{ coinFormat(profile.coin || 0) }}</div>
                </div>
              </div>
            </div>
            <div class="list-categories" v-if="categories.some(category=>!isEmptyItems(category.slug)&&category.slug!=='clients'&&category.slug!=='objects')">
              <div class="options-separator">
                <div class="options-separator__line"></div>
              </div>
              <template  v-for="category in categories">
                <div
                    v-if="!isEmptyItems(category.slug)&&category.slug!=='clients'&&category.slug!=='objects'"
                    :key="category.slug"
                    class="cell-option"
                    :class="category.slug"
                    @click="openCategory(category)"
                >
                  <div class="cell-option__left">
                    <icon-component
                        :icon="category.iconSrc"
                        :color="'var(--colorIconAccent)'"
                        :size="24"
                    />
                  </div>
                  <div class="cell-option__middle">{{ $t(category.label || "") }}</div>
                </div>
              </template>
            </div>
            <div class="options-separator">
              <div class="options-separator__line"></div>
            </div>
            <div class="select-theme">
              <div class="cell-option" @click="toggleVisibilityTheme">
                <div class="cell-option__left">
                  <icon-component
                      :icon="'/default-icons/light_mode.svg'"
                      :color="'var(--colorIconAccent)'"
                      :size="24"
                  />
                </div>
                <div class="cell-option__middle">{{$t('ВнешнийВид')}}</div>
              </div>
              <div class="list-themes-wrapper" v-if="visibleSwitcherTheme">
                  <div class="options-theme">
                    <div class="options-theme__container" @click.self="hideSwitcherTheme">
                      <div class="selector-list">
                        <div class="selector-list__container">
                          <div class="list-element" @click="$store.commit('SET_BLACK_THEME', false)">
                            <div class="wrapper-point">
                              <input type="radio" :id="'theme_light'" :value="false" v-model="blackTheme">
                            </div>
                            <div class="item-flag">
                              <icon-component
                                  :icon="'/default-icons/light_mode.svg'"
                                  :color="'var(--colorIconPrimary)'"
                                  :size="24"
                              />
                            </div>
                            <div class="item-description">{{$t('Светлый')}}</div>
                          </div>
                          <div class="list-element" @click="$store.commit('SET_BLACK_THEME', true)">
                            <div class="wrapper-point">
                              <input type="radio" :id="'theme_light'" :value="true" v-model="blackTheme">
                            </div>
                            <div>
                              <icon-component
                                  :icon="'/default-icons/dark_mode.svg'"
                                  :color="'var(--colorIconPrimary)'"
                                  :size="24"
                              />
                            </div>
                            <div class="item-description">{{$t('Тёмный')}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="options-separator">
              <div class="options-separator__line"></div>
            </div>
            <div class="cell-option" v-if="$store.getters.getRoles.length > 1 || $store.getters.getProfile.role === 'admin'"  @click="changeRoleClick">
              <div class="cell-option__left">
                <icon-component
                    :icon="'/default-icons/account_circle.svg'"
                    :color="'var(--colorIconAccent)'"
                    :size="24"
                />
              </div>
              <div class="cell-option__middle">{{$t('СменитьУчётнуюЗапись')}}</div>
            </div>
            <div class="cell-option" @click="openExitAlert">
              <div class="cell-option__left">
                <icon-component
                    :icon="'/default-icons/logout.svg'"
                    :color="'var(--colorIconAccent)'"
                    :size="24"
                />
              </div>
              <div class="cell-option__middle">{{$t('Выйти')}}</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="navigate"> <!--Алерт выхода из учётной записи-->
      <div class="exit-alert-wrapper" v-if="visibleExitAlert" key="exit-alert">
        <div class="exit-alert-container" v-on-clickaway="hideExitAlert">
          <div class="exit-alert-profile">
            <div class="exit-alert-header">
              <div class="exit-header-icon">
                <icon-component
                    :icon="'/default-icons/arrow_back.svg'"
                    :color="'var(--colorIconAccent)'"
                    :size="24"
                    :clickability="true"
                    @click-element="returnExitAlert"/>
              </div>
              <div class="exit-header-content">
                <img
                    class="exit-header-content__avatar"
                    :src="profile.avatar??'/my/noavatar.png'"
                    alt="photo"
                />
              </div>
              <div class="exit-header-icon">
                <icon-component
                    :icon="'/default-icons/close.svg'"
                    :color="'var(--colorIconAccent)'"
                    :size="24"
                    :clickability="true"
                    @click-element="hideExitAlert"
                />
              </div>
            </div>
            <div class="exit-alert-info" v-if="infoUser||infoCompany">
              <div class="exit-alert-info__title" v-if="infoUser">{{infoUser}}</div>
              <div class="exit-alert-info__description" v-if="infoCompany">{{infoCompany}}</div>
            </div>
          </div>
          <div class="exit-alert-btn">
            <default-button
                size="medium"
                @click-element="logout"
            >{{$t('Выйти')}}</default-button>
            <default-button
                v-if="$store.getters.getRoles.length > 1 || $store.getters.getProfile.role === 'admin'"
                size="medium"
                :type="'secondary'"
                @click-element="changeRoleClick"
            >{{$t('СменитьУчётнуюЗапись')}}</default-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { NAVIGATION_CATEGORIES } from "@/router/navigationCategories";
import { isEmpty } from "@/utils";
import getTextRole from "@/mixins/getTextRole";
import IndicatorCounter from "@/components/redesign/IndicatorCounter.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import IndicatorBadge from "@/components/redesign/IndicatorBadge.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import DefaultHeader from "@/components/redesign/defaultHeader.vue";
export default {
  name: "navigation",
  components: {
    DefaultHeader,
    IndicatorBadge,
    IconComponent,
    IndicatorCounter,
    DefaultButton
  },
  mixins: [getTextRole],
  data() {
    return {
      activeNavItem: null,
      currentCategory: null,
      visibleSwitcherTheme: false,
      visibleOptionsMenu:false,
      visibleExitAlert:false,
    };
  },
  props: ["menus", "site"],
  computed: {
    infoUser(){
      let info=this.getTextRole(this.role, this.profile.subrole)
      if(this.profile.director_lastname||this.profile.director_name||this.profile.director_patronymic){
        info+=` · ${this.profile.director_lastname??''} ${this.profile.director_name?`${this.profile.director_name[0]}.`:''}${this.profile.director_patronymic?`${this.profile.director_patronymic[0]}.`:''}`
      }
      return info
    },
    infoCompany(){
      return `${this.profile.org_name?` ${this.$i18n.t('АН')} ${this.profile.org_name}`:''}${this.profile.groupinfo?.name?` «${this.profile.groupinfo.name}»`:''}`
    },
    blackTheme:{
      get:function() {
        return this.$store.getters.getBlackTheme
      },
      // setter
      set: function(newValue) {
        this.$store.commit('SET_BLACK_THEME', newValue)
      }
    },
    changeActiveCategory() {
      if (this.$route.meta.category) {
        return  this.$route.meta.category;
      }
    },
    categories() {
      return NAVIGATION_CATEGORIES;
    },
    routes() {
      return this.getRoutes(this.activeNavItem);
    },
    profile() {
      return this.$store.getters.getProfile;
    },
    role() {
      return this.$store.getters.getRole;
    },
    coinsVisible() {
      return (
        this.$store.getters.getCurrentDomain === "demo" ||
        this.$store.getters.getProfile.menu?.shop
      );
    },
    currentCategoryName() {
      const index = this.categories.findIndex((item) => item.slug === this.activeNavItem);
      return index === -1?"":this.categories[index].label;
    },
    showMyLink() {
      return (
        this.role === "partner" ||
        this.role === "partners" ||
        this.role === "user" ||
        this.role === "bitrix" ||
        this.role === "support" ||
        this.role === "admin"
      );
    },
  },
  methods: {
    isEmpty,
    blockScroll(flag=true, onlyMobile=true) {
      if(onlyMobile){
        if(window.innerWidth<=743) {
          document.body.style.setProperty("overflow", flag ? "hidden" : "")
        }
      } else {
        document.body.style.setProperty("overflow", flag ? "hidden" : "")
      }
    },
    openExitAlert(){ //Открыть модалку выхода, скрыть модалку доп меню
      this.visibleExitAlert=true
      this.hideOptionsMenu()
      this.blockScroll(true)
    },
    hideExitAlert(){ //Скрыть модалку выхода
      this.blockScroll(false)
      this.visibleExitAlert=false
    },
    returnExitAlert(){ //Скрыть модалку выхода и вернуться к модалке доп меню
      this.hideExitAlert()
      this.toggleVisibilityOptionsMenu()
    },
    hideOptionsMenu(){ //cкрыть модалку доп меню и скрыть модалку свитчера темы
      this.blockScroll(false)
      this.visibleOptionsMenu=false
      this.visibleSwitcherTheme=false
    },
    toggleVisibilityOptionsMenu(){
      if(this.visibleOptionsMenu){
        this.hideOptionsMenu()
      } else {
        this.blockScroll(true)
        this.visibleOptionsMenu=true
      }
    },
    hideSwitcherTheme(){
      this.visibleSwitcherTheme = false
    },
    toggleVisibilityTheme () {
      this.visibleSwitcherTheme = !this.visibleSwitcherTheme
    },
    coinFormat(value) {
      if (!value) return "0";
      const formatter = new Intl.NumberFormat("ru-RU", {
        minimumFractionDigits: 0,
      });
      return formatter.format(value || 0) || 0;
    },
    openItemNewTab(path) {
      const routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    openCategory({ slug, path }) {
      this.hideOptionsMenu()
      if (this.getRoutes(slug).length === 1) { //Если всего 1 маршрут в разделе, то сразу открывает первый путь
        const pathSingle = this.getRoutes(slug)[0].path;
        if(this.$route.path !== pathSingle)this.$router.push(pathSingle);

        return;
      }

      if (path) { //Для главной страницы
        if(this.$route.path !== path)this.$router.push(path);
        return
      }

      if(slug!==this.activeNavItem){
        this.changeActiveNavItem(slug)//Если в разделе путей несколько, то будет установлен активный элемент меню и откроется модалка с выбором маршрутов
      }
    },
    closeCategory(path) {
      if (path && this.$route.path !== path) {
        this.$router.push(path).catch(err => {
          // Игнорируем ошибки относящиеся к переходу на ту же самую страницу
          if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // но выводим/обрабатываем все прочие ошибки
            console.error(err);
          }
        });;
      }
      this.hideOptionsMenu()
      this.changeActiveNavItem() //Обнуляем activeNavItem
    },
    closeListPages(element) {
      this.changeActiveNavItem() //Обнуляем activeNavItem
      if(this.activeNavItem!=='clients'&&this.activeNavItem!=='objects'&&element==='dynamic'){
        //Так как все категории, кроме "Клиенты" и "Объекты" находятся в доп меню, то это доп меню необходимо заново открыть после закрытия списка страниц
        // (то есть мы делаем возврат к модалке доп меню)
        // Возврат делаем только, если нажали на динамический элемент в шампке (стрелочка)
        this.toggleVisibilityOptionsMenu()
      }
    },
    changeActiveNavItem(slug=null){
      this.activeNavItem = slug;
      this.blockScroll(!!slug, false) //блокировка скролла происходит на всех экранах
    },
    logout() {
      this.hideExitAlert()
      this.$emit("logout-click");
    },
    clickSupport() {
      this.$emit("support-click");
    },
    clickMyLink() {
      this.hideOptionsMenu()
      this.$emit("my-link-click");
    },
    changeRoleClick() {
      this.hideOptionsMenu()
      this.hideExitAlert()
      this.$emit("role-click");
    },
    isEmptyItems(categorySlug) {
      return this.getRoutes(categorySlug).length === 0;
    },
    getRoutes(slug) {
      let routes = JSON.parse(JSON.stringify(this.$router.options.routes));
      const result = [];

      const isUserProfileEmpty = this.role === "user" && (!this.profile.director_lastname || !this.profile.director_name || !this.profile.city)
      if (isUserProfileEmpty) {
        const index = routes.findIndex((route) => route.path === "/profile");
        routes = index !== -1 ? [routes[index]] : routes;
      }

      const includeSubdomainsRoute =  this.role === "admin" && (this.$root.hostName === "https://building.letsbot.ru" || this.$root.hostName === "http://localhost:8080" || this.$root.hostName === "http://localhost:8081")
      routes.forEach((route) => {
        let showItem = this.menus[route.name] ?? false;

        if (route.path === "/subdomains" && includeSubdomainsRoute) {
          showItem = true;
        }

        if (route.meta.category === slug && showItem) {
          result.push(route);
        }
      });
      return result.sort((a, b) => this.$i18n.t(a.meta.label).toLowerCase().localeCompare(this.$i18n.t(b.meta.label).toLowerCase()));
    },
  },
  updated() {
    this.$store.dispatch("SET_NAVIGATION", this.menus);
  },
};
</script>

<style lang="scss">
$nav-item-transition: 0.3s ease;
$tile-inside-transition: 0.3s ease;
$nav-width: 80px;
$nav-icon-side: 28px;


@keyframes show-modal {
  0%{
    transform: translate3d(0px, 100%, 0px);
  }
  100%{
    transform: translate3d(0px, 0, 0px);
  }
}
@keyframes show-back {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.options-theme{
  position: absolute;
  z-index: 1000;
  top:calc(100% + 8px);
  width: 100%;
  min-width: min-content;
  .options-theme__container{
    .selector-list{
      padding: 4px 0;
      border-radius: 8px;
      border: 0.5px solid var(--colorStrokeSecondary);
      background-color: var(--colorComponentDropdownListItemBackground);
      box-shadow: 0px 0px 8px 0px rgba(30, 30, 36, 0.10), 0px 8px 16px 0px rgba(23, 23, 28, 0.05);
      text-transform: none;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .selector-list__container{
        display: flex;
        flex-direction: column;

        height: max-content;
        max-height: 216px;
        overflow-y: auto;
        overflow-x:hidden;
        &::-webkit-scrollbar{
          width: 5px;
        }
        &::-webkit-scrollbar-track{
          background: transparent;
        }
        &::-webkit-scrollbar-thumb{
          border-radius: 8px;
          background: var(--colorComponentScrollbarKnob);
        }
        .list-element{
          display: flex;
          align-items: center;
          gap:8px;
          padding: 8px 12px;
          cursor: pointer;
          &:hover{
            background: var(--colorComponentDropdownListItemBackgroundHover);
          }
          .wrapper-point{
            display: flex;
            padding: 2px;
          }
          .item-description{
            color: var(--colorTextPrimary);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            letter-spacing: 0.016px;
          }
          input[type=radio]{
            min-width: 20px;
            min-height: 20px;
            max-width: 20px;
            max-height: 20px;
          }
        }
      }
    }
  }
}

.exit-alert-wrapper{
  position: fixed;
  z-index: 3000;
  left: 88px;
  bottom: 8px;
  display: flex;
  overflow:auto;
  .exit-alert-container{
    height: fit-content;
    background: var(--colorBackgroundContent);
    border-radius:12px;
    border: 0.5px solid var(--colorStrokeSecondary);
    padding: 16px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width:304px;
    .exit-alert-profile{
      display: flex;
      flex-direction: column;
      gap: 12px;
      .exit-alert-header{
        display: flex;
        justify-content: space-between;
        .exit-header-content{
          display: block;
          min-width: 64px;
          max-width: 64px;
          min-height: 64px;
          max-height: 64px;
          position: relative;
          overflow: hidden;
          border-radius: 100px;
          .exit-header-content__avatar{
            width: 100%;
            height: 64px;
            border-radius: 100px;
            object-fit: cover;
          }
        }
      }
      .exit-alert-info {
        align-items: center;
        gap: 4px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .exit-alert-info__title{
          color: var(--colorTextPrimary);
          text-align: center;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
          letter-spacing: 0.024px;
        }
        .exit-alert-info__description{
          color: var(--colorTextSecondary);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.07px;
        }
      }
    }
    .exit-alert-btn{
      padding-top: 24px;

      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.options-menu-wrapper{
  position: fixed;
  z-index: 3000;
  left: 88px;
  bottom: 8px;
  display: flex;
  overflow:auto;
  max-height: calc(100vh - 16px);
  .options-menu-container{
    height: fit-content;
    background: var(--colorBackgroundContent);
    border-radius:12px;
    border: 0.5px solid var(--colorStrokeSecondary);
    padding: 0 8px 4px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width:304px;
    .options-menu-profile{
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px;
      .options-menu-header{
        display: flex;
        justify-content: space-between;
        .options-header-icon{
          .header-logo{
            width: 24px;
            height: 24px;
            .header-logo__picture {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 4px;
            }
          }
        }
        .options-header-content{
          display: block;
          min-width: 64px;
          max-width: 64px;
          min-height: 64px;
          max-height: 64px;
          position: relative;
          overflow: hidden;
          border-radius: 100px;
          .options-header-content__avatar{
            width: 100%;
            height: 64px;
            border-radius: 100px;
            object-fit: cover;
          }
        }
      }
      .options-menu-info{
        align-items: center;
        gap: 4px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .options-menu-info__title{
          color: var(--colorTextPrimary);
          text-align: center;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
          letter-spacing: 0.024px;
        }
        .options-menu-info__description{
          color: var(--colorTextSecondary);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.07px;
        }
      }
      .options-menu-link-profile{
        display: flex;
        justify-content: center;
      }
    }
    .options-menu-body{
      .cell-option{
        display: flex;
        align-items: center;
        min-height: 48px;
        padding: 0 12px;
        border-radius: 8px;
        transition: all 0.3s;
        cursor: pointer;
        &:hover{
          background: var(--colorBackgroundTertiaryHover);
        }
        .cell-option__left{
          padding: 4px 12px 4px 0;
        }
        .cell-option__middle{
          padding: 10px 0;
          color: var(--colorTextPrimary);

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
          letter-spacing: 0.024px;
          width: 100%;
        }
        .cell-option__right{

        }
        .chip-coin{
          display: flex;
          min-height: 20px;
          padding: 2px 4px;
          justify-content: center;
          align-items: center;
          gap: 2px;

          border-radius: 6px;
          background: var(--colorAccentGold);
          .chip-coin__label{
            color: var(--colorTextContrastInvariably);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px; /* 116.667% */
            letter-spacing: 0.03px;
          }
        }
      }
      .options-separator{
        display: flex;
        height: 4px;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        .options-separator__line{
          height: 1px;
          width: 100%;
          background: var(--colorStrokeSecondary);
        }
      }
      .select-theme{
        position: relative;
      }
      .list-categories{
        display: none;
      }
    }
  }
}

.modal-list-pages-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorOverlay);
  z-index: 2900;
}

.navigation-bar {
  .navigation {
    background: var(--colorComponentNavigationBackground);
    transition: background 0.5s ease, border 0.5s ease;
    z-index: 3000;
    top: 0;
    left: 0;
    bottom: 0;
    width: $nav-width;
    position: fixed;
    border-right: 0.5px solid var(--colorStrokePrimary);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: var(--colorComponentScrollbarKnob);
    }

    .nav-logo-wrapper {
      display: flex;
      height: 64px;
      padding: 8px 0;
      justify-content: center;
      align-items: center;
      .nav-logo-container{
        width: 48px;
        height: 48px;
        .nav-logo-container__picture {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 4px;
        }
      }
    }
    .nav-item {
      position: relative;
      display: flex;
      height: 64px;
      min-width: 64px;
      padding: 8px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      cursor: pointer;
      border-radius: 8px;
      transition: all 0.2s ease;
      &:not(.active):hover {
        background-color: var(--colorBackgroundTertiaryHover);
      }
      &.active{
        .nav-icon {
          background-color: var(--colorIconAccentInvariably);
        }
        .nav-label {
          color: var(--colorTextAccentInvariably);
        }
      }

      &.support {
        position: relative;
        .chat-indicator-message {
          position: absolute;
          top: 0;
          right: 10px;
        }
      }

      .nav-icon {
        display: block;
        width: $nav-icon-side;
        height: $nav-icon-side;
        background-color: var(--colorIconSecondary);
        transition: background-color $nav-item-transition;
      }

      .nav-label {
        overflow: hidden;
        color: var(--colorTextSecondary);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px; /* 120% */
        letter-spacing: 0.01px;

        transition: color $nav-item-transition;
      }
      .nav-indicator{
        position: absolute;
        right: 14px;
        top: 13px;
      }
      .nav-avatar{
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        .nav-avatar__el{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 24px;
        }
      }
    }

    .nav-separator {
      flex-grow: 1;
    }
  }

  .navigation-tiles {
    position: absolute;
    padding: 16px;
    top: 0;
    left: $nav-width;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    max-width:1280px;
    max-height: 100vh;
    overflow: auto;
    .nav-tile {
      border-radius: 8px;
      background: var(--colorBackgroundContent);

      /* Light/Elevation L */
      box-shadow: 0px 16px 32px 0px rgba(23, 23, 28, 0.10), 0px 0px 16px 0px rgba(23, 23, 28, 0.15);

      padding: 16px;
      width: 240px;
      height: 160px;
      cursor: pointer;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      transition: all $tile-inside-transition;

      &:hover {
        background-color: var(--colorBackgroundAccentInvariably);

        .tile-info {
          color: var(--colorTextContrastInvariably);
        }
        .tile-icon {
          background-color: var(--colorIconContrastInvariably);
        }
      }

      .tile-info {
        width: 100%;
        transition: color $tile-inside-transition;

        color: var(--colorTextPrimary);

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: 0.024px;
      }
      .tile-icon {
        background-color: var(--colorIconAccent);
        width: 28px;
        min-width: 28px;
        height: 28px;
        transition: background-color $tile-inside-transition;
      }
    }
  }

  .navigation-tiles-mobile{
    display: none;


    background: var(--colorBackgroundContent);

    flex-direction: column;
    max-height: 100%;
    min-height: 200px;

    border-radius: 12px 12px 0 0;
    margin: auto auto 0;
    width: 100%;
    .navigation-tiles-mobile__body{
      flex-grow: 1;
      padding: 0 16px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      .body-modal-padding{
        flex-grow: 1;
        padding: 12px 0;
        .nav-tile {
          display: flex;
          align-items: center;
          min-height: 48px;
          padding: 0 16px;
          border-radius: 8px;
          cursor: pointer;
          .tile-info {
            padding: 10px 0;
            color: var(--colorTextPrimary);

            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            letter-spacing: 0.024px;
            width: 100%;
          }
          .tile-icon {
            padding: 4px 12px 4px 0;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: var(--colorComponentScrollbarKnob)
      }
    }
  }
}

@media (max-width: 1090px) {
  .options-menu-wrapper, .exit-alert-wrapper{
    left: unset;
    right: 8px;
    bottom: 56px;
    max-height: calc(100vh - 64px);
  }
  .modal-list-pages-wrapper {
    bottom: 48px;
    .modal-list-pages{
      height: 100%;
      width: 100%;
      display: flex
    ;
      overflow: auto;
      animation-name: show-modal;
      animation-duration: 0.5s;
    }
  }
  .options-menu-wrapper{
    .options-menu-container{
      .options-menu-body{
        .list-categories{
          display: block;
        }
      }
    }
  }


  .navigation-bar {

    .navigation {
      top: unset;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      box-shadow: 0 0 8px 0 rgba(30, 30, 36, 0.1), 0 8px 16px 0 rgba(23, 23, 28, 0.05);

      .nav-logo-wrapper,
      .nav-separator,
      .manage,
      .dev{
        display: none;
      }

      .nav-item {
        height: 48px;
        padding: 4px 0 4px;
      }
    }

    .navigation-tiles{
      display: none;
    }
    .navigation-tiles-mobile {
      display: flex;
      .navigation-tiles-mobile__body{
        padding: 0 8px;
      }
    }

  }
}


@media screen and (max-width: 743px){
  .options-theme{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3500;
    background: var(--colorOverlay);

    animation-name:show-back;
    animation-duration: 0.5s;
    width:100%;
    .options-theme__container{
      padding: 16px;
      height: 100%;
      width: 100%;
      display: flex;
      overflow: auto;

      animation-name:show-modal ;
      animation-duration: 0.5s;
      .selector-list{
        margin: auto auto 0;
        width: 100%;
        max-height: 100%;
        .selector-list__container{
          max-height: unset;
        }
      }
    }
  }
  .options-menu-wrapper,.exit-alert-wrapper{
    padding: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: end;
    background: var(--colorOverlay);
    max-height: unset;
    .options-menu-container,.exit-alert-container{
      width: 100% ;
      margin: auto auto 0;
    }
  }
}


.navigate-enter-active,
.navigate-leave-active {
  transition: opacity 0.2s;
}

.navigate-enter,
.navigate-leave-to {
  opacity: 0;
}
</style>
