import { render, staticRenderFns } from "./spectrumSlider.vue?vue&type=template&id=3713db54&scoped=true"
import script from "./spectrumSlider.vue?vue&type=script&lang=js"
export * from "./spectrumSlider.vue?vue&type=script&lang=js"
import style0 from "./spectrumSlider.vue?vue&type=style&index=0&id=3713db54&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3713db54",
  null
  
)

export default component.exports