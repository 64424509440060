var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{ref:"floatWindow",staticClass:"floating-window",style:({
  left: _vm.position.x + 'px',
  top: _vm.position.y + 'px',
  cursor: _vm.isDragging ? 'move' : 'default',
  width: _vm.width + 'px',
  maxWidth: _vm.width + 'px',
})},[_c('div',{staticClass:"window-header",on:{"mousedown":_vm.startDrag,"mouseup":_vm.stopDrag}},[_c('div',{staticClass:"header-label"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"header-close"},[_c('div',{staticClass:"header-cross",on:{"click":function($event){$event.stopPropagation();_vm.visible = false}}})])]),_c('div',{staticClass:"window-content"},[_vm._t("default")],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }