<template>
  <div class="textarea-wrapper" :class="[appearance,classComponent]" @click="focusArea">
    <div class="textarea-el" :class="{'disabled':disabled,'active':activeComponent}">
      <div
          :max-length="maxLength"
          ref="areaField"
          class="textarea-el__field"
          :class="{'holder':!value}"
          :contentEditable="!disabled"
          @input="setValue"
          @focus="onActiveStatus"
          @blur="offActiveStatus"
          @keydown="preventNewLineIfMax"
          :placeholder="placeholder"
      ></div>
    </div>
    <div v-if="maxLength !== null" class="textarea-counter">
      {{length}}/{{maxLength}}
    </div>
  </div>
</template>

<script>
export default {
  /**
   * @prop value
   *
   * @prop {String} appearance - Отвечает за внешний вид textarea и имеет следующие состояния:
   *
   * 1.default-appearance (дефолтное состояние)
   * 2.error
   * @prop {String} classComponent - Отвечает за внешний вид textarea и имеет следующие состояния:
   * 1.default-class (дефолтное состояние) - 320px-1365px:regular; 1366px+:compact
   * 2.compact
   * 2.regular
   *
   * @prop {String} placeholder - Значение плейсхолдера
   * @prop {Boolean} disabled - Блокировка поля
   */


  name: "textareaComponent",
  props:{
    value:{},
    placeholder:{
      type: String,
      default: '',
    },
    appearance: {
      type: String,
      default: 'default-appearance'
    },
    classComponent: {
      type: String,
      default: 'default-class'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    }
  },
  data(){
    return{
      activeComponent:false,
      length: 0
    }
  },
  watch:{
    value(newVal){
    const field = this.$refs.areaField
    if (field && field.innerText !== newVal) {
      const selection = window.getSelection();
      const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
      const cursorPos = range ? range.startOffset : field.innerText.length;
      field.innerText = newVal;

      this.$nextTick(() => {
        if (range) {
          const newRange = document.createRange();
          newRange.setStart(field.childNodes[0] || field, Math.min(cursorPos, newVal.length));
          newRange.collapse(true);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      });
      }
    }
  },
  methods:{
setValue(event) {
  const field = event.target;
  if (!field) return;

  let text = field.innerText;

  // Ограничение по максимальному колву символов
  if (this.maxLength !== null && text.length > this.maxLength) {
    const selection = window.getSelection();
    const cursorPos = selection.focusOffset;
    text = text.substring(0, this.maxLength);
    field.innerText = text;
    this.restoreCursor(field, Math.min(cursorPos, text.length));
  }

  this.length = text.length;

  if (!this.disabled) {
    this.$emit('input', text.trim().length === 0 ? '' : text);
  }
},
preventNewLineIfMax(event) {
  const field = event.target;
  const text = field.innerText;
  if (event.ctrlKey || event.metaKey) {
    return;
  }
  if (this.maxLength !== null && text.length >= this.maxLength && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.preventDefault();
  }
},
restoreCursor(field, cursorPos) {
  this.$nextTick(() => {
    const selection = window.getSelection();
    const range = document.createRange();
    
    if (!field.firstChild) {
      return;
    }

    const textLength = field.innerText.length;
    const newPos = Math.min(cursorPos, textLength);

    try {
      range.setStart(field.firstChild, newPos);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    } catch (error) {
      console.warn("Ошибка установки курсора:", error);
    }
  });
},
    focusArea(){
      if(!this.disabled){
        this.$refs.areaField.focus()
      }
    },
    onActiveStatus(){
      if(!this.disabled){
        this.activeComponent=true
      }
    },
    offActiveStatus(){
      if(!this.disabled){
        this.activeComponent=false
      }
    }
  },
  mounted() {
    if(this.value)this.$refs.areaField.innerText=this.value
  }
}
</script>

<style scoped lang="scss">
@mixin compactView(){
  .textarea-el{
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.035px;
  }
}
@mixin regularView(){
  .textarea-el{
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.016px;
  }
}

.textarea-wrapper{
  position: relative;
  .textarea-el{
    cursor: text;
    width: 100%;
    border-radius: 8px;
    border: 0.5px solid var(--colorComponentFieldStroke);
    background: var(--colorComponentFieldBackground);
    transition: all 0.2s ease;
    .textarea-el__field{
      width: 100%;
      min-height: 40px;
      max-height: 180px;
      overflow: auto;
      border: 0;
      outline: none;

      color: var(--colorTextPrimary);
      font-style: normal;
      &::-webkit-scrollbar{
        width: 6px;
      }
      &::-webkit-scrollbar-track{
        background: transparent;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 8px;
        background: var(--colorComponentScrollbarKnob);
        cursor: default;
      }
    }
/*    .textarea-el__field[contentEditable=true]:empty:before {
      content: attr(placeholder);
      color: var(--colorTextSecondary);
    }*/
    .holder:before{
      content: attr(placeholder);
      color: var(--colorTextSecondary);
    }
    &:not(.active):not(.disabled):hover{
      background: var(--colorComponentFieldBackgroundHover);
    }
    &.active{
      background: var(--colorComponentFieldBackgroundActive);
      border: 0.5px solid var(--colorStrokeAccentExtraInvariably);
    }
    &.disabled{
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .textarea-counter {
    right: 10px;
    top: -22px;
    position: absolute;
    color: var(--colorTextSecondary);
  }
  &.error{
    .textarea-el{
      border: 0.5px solid var(--colorStrokeNegative);
      background: var(--colorBackgroundNegativeTint);
      &:not(.active):not(.disabled):hover{
        background: var(--colorBackgroundNegativeTintHover);
      }
      &.active{
        background: var(--colorComponentFieldBackgroundActive);
      }
    }
  }

  &.default-class{
    @include compactView;
  }
  &.regular{
    @include regularView;
  }
  &.compact{
    @include compactView;
  }


}

@media screen and (max-width: 1365px){
  .textarea-wrapper{
    &.default-class{
      @include regularView;
    }
  }
}
</style>