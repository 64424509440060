<script>
import dropdownComponent from "@/components/dropdown.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import priceFormat from "@/mixins/priceFormat";
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import FunctionsTable from "@/components/redesign/Table/FunctionsTable.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import TableComponent from "@/components/redesign/Table/tableComponent.vue";
import axios from "axios";
import TableRowChips from "@/components/redesign/Table/tableRowChips.vue";
import AlertModal from "@/components/redesign/alertModal.vue";
import Loader from "@/components/Loader.vue";
import InfiniteLoader from "@/components/redesign/infiniteLoader.vue";

export default {
  name: "RepairsLayout",
  components: {
    InfiniteLoader,
    Loader,
    AlertModal,
    TableRowChips,
    TableComponent,
    IconComponent,
    FunctionsTable,
    DefaultButton,
    OptionsRow,
    ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data() {
    return {
      pageCount: null,
      page: 1,
      search: null,
      repair: null,
      visibleConfirmArchive: null,
      repairs: [],
      rooms: [],
      banks: {},
      tabs: [
        {
          value: this.$i18n.t('Актуальное'),
          id: 'active',
          icon: '/default-icons/task.svg',
          action: 'archive'
        },
        {
          value: this.$i18n.t('Архив'),
          id: 'archive',
          icon: '/default-icons/history.svg',
          action: 'active'
        }
      ],
      activeTab: {},
      isLoading: false
    }
  },
  watch: {
    page() {
      if(this.page !== 1) {
        this.getRepairs()
      }
    },
    search() {
      this.resetPage()
    },
    activeTab: {
      handler(after) {
        this.resetPage()
      },
      deep: true
    },
  },
  methods:{
    resetPage() {
      this.page = 1
      this.repairs = []
      this.pageCount = null

      this.getRepairs()
    },
    getChipsByDevelopments(el) {
      return el.map(el => el.name + ' - ' + el.house)
    },
    getName(el) {
      return (el ?? []).map(el => {
        let str = ''
        if(el.id) {
          str +=  `[${el.id}]`
        }
        return str + el.name
      })
    },
    getValue(el) {
      return (el ?? []).map(el => el.value)
    },
    async getRepairs() {
      this.isLoading = true
      const res = await axios.get('/calculator/repairs/get-all', {
        params: {
          mode: this.activeTab.id,
          search: this.search,
          page: this.page
        }
      })
          .then(res => res.data)
          .catch(e => [])

      this.pageCount = res?.pageCount
      this.repairs.push(...(res.data ?? []))
      this.isLoading = false
    },
    addRepair() {
      this.$router.push({name: 'create-repair'})
    },
    editRepair(repair) {
      this.$router.push({name: 'edit-repair', params: {
        id:repair.id
      }})
    },
    async action() {
      const res = await axios.get(`calculator/repairs/${this.activeTab.action}?id=` + this.repair.id)
          .then(res => res.data)
          .catch(e => {})

      this.visibleConfirmArchive= false
      this.resetPage()
    },
    confirmAction(repair) {
      this.repair = repair
      if(this.activeTab.id === 'archive') {
        this.action()
        return;
      }

      this.visibleConfirmArchive = true
    },
  },
  mounted() {
    this.activeTab = this.tabs[0]
  }
}
</script>

<template>
  <div>
    <alert-modal
        v-if="visibleConfirmArchive"
        :centralText="true"
        :title="$t('ОтключениеРемонта')"
        description="Вы уверены что хотите отключить этот ремонт?"
        :confirmText="$t('Отключить')"
        :visibleCancelBottomButton="true"
        @confirm-alert="action"
        @cancel-alert="visibleConfirmArchive = false"
    />
    <functions-table
        :tabs="tabs"
        @update:activeTab="(value) => this.activeTab = value"
        @update:searchQuery="(value) => this.search = value"
        @add-row="addRepair"
    >
    </functions-table>
    <table-component
        v-if="repairs.length"
        :visible-title="false"
        v-model="repairs"
        :colspan="8"
    >
      <template #header-table>
        <th>{{ $t('ID') }}</th>
        <th>{{ $t('Название') }}</th>
        <th>{{ $t('Стоимость') }}</th>
        <th>{{ $t('Комнатность') }}</th>
        <th>{{ $t('Площадь') }}</th>
        <th>{{ $t('Строение') }}</th>
        <th>{{ $t('БПВ') }}</th>
        <th>{{ $t('Программы') }}</th>
      </template>
      <template #content-table="{el}">
        <td>{{el.id}}</td>
        <td>{{el.name}}</td>
        <td>{{priceFormat(el.price)}}</td>
        <td>
          <table-row-chips
              :chips="getValue(el.types_objects)"
          />
        </td>
        <td>
          <template v-if="el.square_min != null">{{el.square_min}} м²</template>
          <template v-if="el.square_max != null"> - {{el.square_max}} м²</template>
        </td>
        <td>
          <table-row-chips
              :chips="getChipsByDevelopments(el.developments)"
          />
        </td>
        <td>
          <template v-if="el.bpv === 1">{{ $t('Применяется') }}</template>
          <template v-if="el.bpv === 0">{{ $t('НеПрименяется') }}</template>
        </td>
        <td>
          <table-row-chips
              :chips="getName(el.programs)"
          />
        </td>
        <td class="options">
          <options-row
              :activeTab="activeTab?.id"
              @read-more="editRepair(el)"
              @archive="confirmAction(el)"
          />
        </td>
      </template>
    </table-component>
    <infinite-loader
        :is-loading="isLoading"
        :page-count="pageCount"
        :page="page"
        @change-page="(value) => page = value"
    >
    </infinite-loader>
  </div>
</template>

<style scoped lang="scss">

</style>