<template>
  <div class="circle-color-wrapper">
    <div class="circle-color-palette" @click="callEvent">
      <div class="circle-color-palette-substrate"></div>
      <div class="circle-color-palette-color" :style="{backgroundColor: color}"></div>
      <div class="circle-color-palette-overlay" :style="{opacity: selected ? 1 : 0, background: changeTextColor(color.toString())}"></div>
    </div>
  </div>
</template>

<script>
import {changeTextColor, ColorRGBA} from "@/components/redesign/colorPickerComponent/colorPickerHelpers";

export default {
  name: "circleColorPalette",
  props: {
    color: {
      type: ColorRGBA,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeTextColor,
    callEvent() {
      this.$emit('color-selected', this.color);
    }
  }
}
</script>

<style scoped lang="scss">
.circle-color-wrapper{
  .circle-color-palette {
    position: relative;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;

    &-color, &-substrate, &-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &-substrate {
      background: url('/public/default-icons/transparent_back.png') repeat;
    }

    &-overlay {
      opacity: 0;
      -webkit-mask: url('/public/default-icons/check_circle_fill_inverse.svg') no-repeat center / contain;
      mask: url('/public/default-icons/check_circle_fill_inverse.svg') no-repeat center / contain;
      transition: opacity 0.2s ease;
    }
  }
}
</style>