export default {
    data() {
        return {
            exchangeRates: {}
        };
    },

    mounted() {
        this.exchangeRates = this.$store.state.exchangeRates;
    },
    methods: {
        formatApiCurrency(value, currency) {
            const minimumFractionDigits = value >= 100 || value === 0 ? 0 : 2;
            return new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency,
                minimumFractionDigits,
                maximumFractionDigits: minimumFractionDigits
            }).format(value || 0);
        },

        priceFormat(value) {
            const baseCurrency = this.$store.getters.getCurrency.description; 
            const targetCurrency = this.$store.getters.getNewCurrency.description;
            if (!targetCurrency || targetCurrency === baseCurrency || baseCurrency !== 'USD') {
                return this.formatApiCurrency(value, baseCurrency);
            }

            const rateKey = `${baseCurrency}${targetCurrency}`;
            const rate = this.exchangeRates[rateKey];

            if (!rate) {
                console.warn(`Курс для ${rateKey} не найден`);
                return this.formatApiCurrency(value, baseCurrency);
            }

            const convertedValue = value * rate;
            return this.formatApiCurrency(convertedValue, targetCurrency);
        }
    }
};
