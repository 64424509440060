<template>
  <div class="floating-window" v-if="visible" ref="floatWindow" :style="{
    left: position.x + 'px',
    top: position.y + 'px',
    cursor: isDragging ? 'move' : 'default',
    width: width + 'px',
    maxWidth: width + 'px',
  }">
    <div class="window-header" @mousedown="startDrag" @mouseup="stopDrag">
      <div class="header-label">{{ title }}</div>
      <div class="header-close">
        <div class="header-cross" @click.stop="visible = false"></div>
      </div>
    </div>
    <div class="window-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {Position} from "@/store/models/position";

export default {
  name: "floatingWindow",
  data() {
    return {
      isDragging: false,
      position: new Position(0,0),
      offsetX: 0,
      offsetY: 0,
    }
  },
  props: {
    bounds: {
      required: true
    },
    title: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 304
    },
    startPosition: {
      type: Position,
      default() {
        return new Position(0,0)
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    async startDrag(event) {
      this.isDragging = true;
      const rect = this.$refs.floatWindow.getBoundingClientRect();
      this.offsetX = event.clientX - rect.left;
      this.offsetY = event.clientY - rect.top;

      window.addEventListener('mousemove', this.updateWindowPosition);
      window.addEventListener('mouseup', this.stopDrag);
    },
    stopDrag() {
      this.isDragging = false;
      window.removeEventListener('mousemove', this.updateWindowPosition);
      window.removeEventListener('mouseup', this.stopDrag);
    },
    async updateWindowPosition(event) {
      const rect = this.bounds;
      const windowRect = this.$refs.floatWindow.getBoundingClientRect();

      const x = this.calcCoordinate(event.clientX - this.offsetX, rect.left, rect.width, windowRect.width);
      const y = this.calcCoordinate(event.clientY - this.offsetY, rect.top, rect.height, windowRect.height);

      this.position = new Position(x, y)
    },
    calcCoordinate(coordinate, axisCoordinate, side, size) {
      let newCoordinate = coordinate - axisCoordinate;

      if (newCoordinate < 0) {
        newCoordinate = 0;
      }

      if (newCoordinate > side - size) {
        newCoordinate = side - size;
      }

      return newCoordinate;
    },
  },
  mounted() {
    this.position = this.startPosition
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.updateWindowPosition);
    window.removeEventListener('mouseup', this.stopDrag);
  },
  watch: {
    startPosition: {
      deep: true,
      handler(val) {
        this.position = val;
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .floating-window{
   user-select: none;
   position: absolute;
   min-width: 304px;
   min-height: 304px;
   border-radius: 12px;
   border: 0.5px solid var(--colorStrokeSecondary);
   background: var(--colorBackgroundContent);
   box-shadow: 0px 8px 16px 0px rgba(23, 23, 28, 0.05), 0px 0px 8px 0px rgba(30, 30, 36, 0.10);
   max-height: 700px;
   overflow-y: auto;

   .window-content {
     padding: 0 16px 16px 16px;
   }

   .window-header {
     padding: 16px 16px 8px 16px;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;

     .header-label {
       color: var(--colorTextSecondary);

       font-family: Inter,serif;
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: 18px;
       letter-spacing: 0.035px;
     }

     .header-close {
       width: 16px;
       height: 16px;
       border-radius: 100%;
       cursor: pointer;

       &:hover {
        background-color: var(--colorBackgroundAlpha);
       }

       .header-cross {
         mask: url("/public/default-icons/close.svg") no-repeat center / contain;
         background-color: var(--colorIconAccent);
         width: 100%;
         height: 100%;
       }
     }
   }
 }
</style>