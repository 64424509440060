import {rgbToHex} from "painterro/js/colorPicker";

export function defaultColor() {
    return new ColorRGBA(255, 79, 79, 1)
}

export function findColorPosition(imageData, width, height, targetColor, tolerance = 0, step = 1) {
    const targetRGB = [
        targetColor.r,
        targetColor.g,
        targetColor.b
    ];

    const colorsMatch = (r, g, b, targetRGB, tolerance) => {
        return (
            Math.abs(r - targetRGB[0]) <= tolerance &&
            Math.abs(g - targetRGB[1]) <= tolerance &&
            Math.abs(b - targetRGB[2]) <= tolerance
        );
    };

    for (let y = 0; y < height; y += step) {
        for (let x = 0; x < width; x += step) {
            const index = (y * width + x) * 4; //каждый канал занимает 1 байт. 4 канала - 4 байта
            const r = imageData[index];
            const g = imageData[index + 1];
            const b = imageData[index + 2];

            if (colorsMatch(r, g, b, targetRGB, tolerance)) {
                return { x: x, y: y };
            }
        }
    }

    return null;
}

export function tryGetClientRects(element, timeout, tries) {
    return new Promise((resolve, reject) => {
        let currentTry = 0

        const interval = setInterval(() => {
            try {
                currentTry++;
                const rects = element.getBoundingClientRect()

                if (rects.width > 0 || rects.height > 0) {
                    resolve(rects);
                    clearInterval(interval);
                }

                if (currentTry >= tries) {
                    reject()
                    clearInterval(interval);
                }
            } catch (e) {
                clearInterval(interval);
            }
        }, timeout);
    })
}

/**
 * Меняет цвет текста заданного элемента в зависимости от яркости фона
 * @param {string} hex - Цвет фона
 * @returns {string} - Новый цвет текста в HEX
 */
export function changeTextColor(hex) {
    const threshold = 250 //менять, если неправильно определяет яркость
    const rgbColor = hexToRgba(hex, 'text color change')
    const brightness = (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000

    if (brightness < threshold) {
        return '#fff'
    }

    return '#000'
}

/**
 * Преобразование HEX в RGB с альфа-каналом
 *
 * @param {string} hex
 * @returns ColorRGBA
 */
export function hexToRgba(hex, who) {
    //console.log(who, hex)
    hex = hex.replace('#', '');

    if (hex.length === 8) {
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        const a = parseInt(hex.slice(6, 8), 16) / 255;

        return new ColorRGBA( r, g, b, a );
    }

    if (hex.length === 6) {
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        const a = 1;

        return new ColorRGBA(r, g, b, a );
    }

    throw 'Неверный формат HEX цвета';
}

/**
 * Преобразование RGB с альфа-каналом в HEX
 *
 * @param {{r:number, g:number, b:number, a:number}} ColorRGBA
 * @returns {string}
 */
export function rgbaToHex({ r, g, b, a }) {
    //TODO ызывается слишклм много раз почему-то из-за этого лагает
    r = Math.min(255, Math.max(0, Math.round(r)));
    g = Math.min(255, Math.max(0, Math.round(g)));
    b = Math.min(255, Math.max(0, Math.round(b)));
    a = Math.min(1, Math.max(0, a));

    let hex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

    if (a < 0.99) {
        const alphaHex = Math.round(a * 255).toString(16).padStart(2, '0');
        hex = hex + alphaHex;
    }

    return hex.toUpperCase();
}

export class ColorRGBA {
    r = 0
    g = 0
    b = 0
    a = 0

    constructor(r, g, b, a) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

    get [Symbol.toStringTag]() {
        return rgbaToHex(this);
    }

    toString() {
        return rgbaToHex(this);
    }
}

export class SliderPosition {
    x = 0
    y = 0

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}