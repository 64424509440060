<template>
  <div class="matrix-spectrum-palette" ref="matrixSpectrumPalette">
    <spectrum-slider :axes="['x', 'y']" parent-ref="matrixSpectrumPalette" @position-changed="setColor" :position="sliderPosition"></spectrum-slider>
    <canvas class="matrix-layer" ref="matrixCanvas"></canvas>
  </div>
</template>

<script>
import {
  ColorRGBA, defaultColor, findColorPosition,
  SliderPosition,
  tryGetClientRects
} from "@/components/redesign/colorPickerComponent/colorPickerHelpers";
import SpectrumSlider from "@/components/redesign/colorPickerComponent/spectrumSlider.vue";

export default {
  name: "matrixSpectrumPalette",
  components: {SpectrumSlider},
  data() {
    return {
      lastColor: new ColorRGBA(0,0,0,1),
      sliderPosition: new SliderPosition(0, 0)
    }
  },
  props: {
    color: {
      type: ColorRGBA,
      default() {
        return defaultColor()
      }
    }
  },
  methods: {
    async drawMatrixPalette() {
      /*
      * Это написано с использованием шейдеров. Шейдер - программа, которая выполняется на видеокарте. Пока сам мало понимаю шейдеры, будет время разобраться - напишу доку
      * Логика была такая - интерполировать цвет для двумерного градиента. Это было сделано, но так как код в js выполняется на процессоре, то это дико тормозило
      *
      * изначальная реализация, где идет просчет по каждому каналу, за исключением альфа канала. В шейдере логика такая же, но способ написания иной. Черт ногу сломит, короче
      *
      * const context = canvas.getContext("2d", {alpha: false, antialias: false});

  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const normX = x / canvas.width;
      const normY = y / canvas.height;

      const brightness = normY * (0 - 255) + 255;

      const r = normX * (this.color.r * (1 - normY) - brightness) + brightness;
      const g = normX * (this.color.g * (1 - normY) - brightness) + brightness;
      const b = normX * (this.color.b * (1 - normY) - brightness) + brightness;

      context.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
      context.fillRect(x, y, 1, 1);
    }
  }
      * */

      const rects = await tryGetClientRects(this.$refs.matrixSpectrumPalette, 10, 10);
      const canvas = this.$refs.matrixCanvas;

      canvas.width = rects.width;
      canvas.height = rects.height;

      const context = canvas.getContext("webgl", {preserveDrawingBuffer: true});

      if (!context) {
        console.error("WebGL не поддерживается");
        return;
      }

      context.clear(context.COLOR_BUFFER_BIT);

      const vertexShaderSource = `
        attribute vec2 a_position;
        varying vec2 v_texCoord;

        void main() {
            gl_Position = vec4(a_position, 0, 1);
            v_texCoord = a_position * 0.5 + 0.5; // Преобразование в диапазон [0, 1]
        }
    `;

      const fragmentShaderSource = `
        precision mediump float;
        varying vec2 v_texCoord;
        uniform vec3 u_color;

        void main() {
            float normX = v_texCoord.x;
            float normY = 1.0 - v_texCoord.y;

            float brightness = normY * (0.0 - 255.0) + 255.0;

            float r = normX * (u_color.r * (1.0 - normY) - brightness) + brightness;
            float g = normX * (u_color.g * (1.0 - normY) - brightness) + brightness;
            float b = normX * (u_color.b * (1.0 - normY) - brightness) + brightness;

            gl_FragColor = vec4(r / 255.0, g / 255.0, b / 255.0, 1.0);
        }
    `;

      function compileShader(source, type) {
        const shader = context.createShader(type);
        context.shaderSource(shader, source);
        context.compileShader(shader);

        if (!context.getShaderParameter(shader, context.COMPILE_STATUS)) {
          context.deleteShader(shader);
          return null;
        }

        return shader;
      }

      const vertexShader = compileShader(vertexShaderSource, context.VERTEX_SHADER);
      const fragmentShader = compileShader(fragmentShaderSource, context.FRAGMENT_SHADER);

      const program = context.createProgram();
      context.attachShader(program, vertexShader);
      context.attachShader(program, fragmentShader);
      context.linkProgram(program);
      context.useProgram(program);

      const positionBuffer = context.createBuffer();
      context.bindBuffer(context.ARRAY_BUFFER, positionBuffer);

      const positions = new Float32Array([
        -1, -1,
        1, -1,
        -1, 1,
        1, 1,
      ]);

      context.bufferData(context.ARRAY_BUFFER, positions, context.STATIC_DRAW);

      const positionLocation = context.getAttribLocation(program, "a_position");
      context.enableVertexAttribArray(positionLocation);
      context.vertexAttribPointer(positionLocation, 2, context.FLOAT, false, 0, 0);

      const colorLocation = context.getUniformLocation(program, "u_color");
      context.uniform3f(colorLocation, this.color.r, this.color.g, this.color.b);

      context.drawArrays(context.TRIANGLE_STRIP, 0, 4);
      context.finish()
    },
    setColor(sliderPosition) {
      const canvas = this.$refs.matrixCanvas;
      const context = canvas.getContext("webgl");

      if (!context) {
        console.error("WebGL не поддерживается");
        return;
      }

      let pixelColor = new Uint8Array(4);
      const invertedY = canvas.height - sliderPosition.y - 1;
      context.readPixels(sliderPosition.x, invertedY, 1, 1, context.RGBA, context.UNSIGNED_BYTE, pixelColor);

      const error = context.getError();
      if (error !== context.NO_ERROR) {
        console.error("WebGL Error:", error);
      }

      const color = new ColorRGBA(pixelColor[0], pixelColor[1], pixelColor[2], this.color.a);
      this.lastColor = color;

      this.$emit('color-changed', color);
      context.finish()
    },
    presetColor() {
      const canvas = this.$refs.matrixCanvas;
      const context = canvas.getContext("webgl");

      if (!context) {
        console.error("WebGL не поддерживается");
        return;
      }

      const width = canvas.width;
      const height = canvas.height;
      const pixelData = new Uint8Array(width * height * 4);
      context.readPixels(0, 0, width, height, context.RGBA, context.UNSIGNED_BYTE, pixelData);
      const position = findColorPosition(pixelData, width, height, this.color, 1)

      if (!position || (this.sliderPosition && (this.sliderPosition.x === position.x))) {
        return;
      }

      this.sliderPosition = new SliderPosition(position.x, -position.y)
    }
  },
  async mounted() {
    await this.drawMatrixPalette()
  },
  watch: {
    async color() {
      if (this.lastColor.r === this.color.r &&
          this.lastColor.g === this.color.g &&
          this.lastColor.b === this.color.b) {
        return;
      }

      await this.drawMatrixPalette()
      this.presetColor()
    }
  }
}
</script>

<style scoped lang="scss">
.matrix-spectrum-palette {
  height: 272px;
  border-radius: 8px;
  overflow: hidden;
}
</style>