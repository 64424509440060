<template>
  <button
      class="default-btn"
      :class="[{'disabled':disabled}, {'active':active}, {'minimal-btn':leftIcon&&!rightIcon&&!($slots?.default&&$slots.default[0]?.text)}, {'shadow-btn':shadow&&type==='tertiary'}, type, appearance,size]"
      @click="buttonClick"
      :style="[noWrap?{whiteSpace: 'nowrap'}:{}, {width:width}]"
  >
    <loader v-if="isLoading" class="loader" size="small"></loader>
    <template v-else>
      <div class="left-icon-btn" v-if="leftIcon">
        <div class="left-icon-btn__container" :style="`mask: url('${leftIcon}') no-repeat center / contain;  -webkit-mask: url('${leftIcon}') no-repeat center / contain;`"></div>
      </div>

      <div class="btn-main-text" v-if="$slots?.default&&$slots.default[0]?.text"><slot></slot></div>
      <div v-if="tagIndicator||counterIndicator||badgeIndicator">
        <indicator-tag
            v-if="tagIndicator"
            :type="type==='primary'?'primary':'contrast'"
            :size="size==='small'?'small':'medium'"
            :appearance="appearance"
            :text="tagIndicator"
        />
        <indicator-counter
            v-else-if="counterIndicator"
            :type="type==='primary'?'primary':'contrast'"
            :size="size==='small'?'small':'medium'"
            :appearance="appearance"
            :counter="counterIndicator"
        />
        <indicator-badge
            v-else-if="badgeIndicator"
            :appearance="appearance"
        />
      </div>
      <div class="right-icon-btn" v-if="rightIcon">
        <div class="right-icon-btn__container" :style="`mask: url('${rightIcon}') no-repeat center / contain;  -webkit-mask: url('${rightIcon}') no-repeat center / contain;`"></div>
      </div>
    </template>

  </button>
</template>

<script>
import IndicatorTag from "@/components/redesign/IndicatorTag.vue";
import IndicatorCounter from "@/components/redesign/IndicatorCounter.vue";
import IndicatorBadge from "@/components/redesign/IndicatorBadge.vue";
import Loader from "@/components/Loader.vue";

/**
 * Слоты:
 * Дефолтный слот - вставляет текст в кнопку.
 *
 * @prop {Boolean} isLoading - Отвечает за скрытие всего содержимого и отображения лоадера.
 * @prop {Boolean} active - Отвечает за активное состояние кнопки.
 * @prop {Boolean} disabled - Отвечает за блокирование кнопки.
 * @prop {String} type - Отвечает за тип кнопки и имеет следующие состояния:
 * 1.primary (дефолтное состояние)
 * 2.secondary
 * 3.tertiary
 * 4.outline
 *
 * @prop {String} appearance - Отвечает за внешний вид кнопки и имеет следующие состояния:
 * 1.default-appearance (дефолтное состояние)
 * 2.negative
 * 3.neutral
 *
 * @prop {String} size - Отвечает за размер кнопки и имеет следующие состояния:
 * 1.default-size (дефолтное состояние): 1365px и меньше - large, всё что больше 1365px - medium
 * 2.large
 * 3.medium
 * 4.small
 *
 *
 * @prop {Boolean} noWrap - Отключает/включает перенос строк в кнопке. По дефолту перенёс строк отключён. Поставьте пропс в значение false, если хотите включить перенос строк.
 * @prop {String} width - Ширина кнопки
 * @prop {String} leftIcon - Левая иконка
 * @prop {String} rightIcon - Правая иконка
 *
 * @prop {Boolean} shadow - По дефолту тень отключена. Её можно включить только с type=tertiary
 *
 * В кнопке возможны индикаторы 3 типов: тег, счётчик и значок(точка):
 * @prop {String} tagIndicator - текст тега
 * @prop {Number} counterIndicator - цифра/показатель счётчика
 * @prop {Boolean} badgeIndicator - отображение значка(точки)
 *
 * @prop {Boolean} transparentBack (ОТСУТСТВУЕТ, ТАК КАК ЭТО ВОЗМОЖНЫЙ ПРОПС) - Отвечает за отключение/включение прозрачности фона кнопки в дефолтном и disabled состоянии. Работает только с type=tertiary и outline. Фон про наведении и активный фон работают как обычно.
 */
export default {
  name: "defaultButton",
  components: {Loader, IndicatorBadge, IndicatorCounter, IndicatorTag},
  props:{
    isLoading: {
      type: Boolean,
      default: false
    },
    active:{
      type:Boolean,
      default:false
    },
    disabled:{
      type:Boolean,
      default:false
    },
    type: {
      type: String,
      default: 'primary'
    },
    appearance: {
      type: String,
      default: 'default-appearance'
    },
    size:{
      type: String,
      default: 'default-size'
    },
    noWrap:{
      type:Boolean,
      default:true
    },
    width: {
      type: String,
      default: ''
    },
    leftIcon:{
      type:String,
      default:''
    },
    rightIcon:{
      type:String,
      default:''
    },
    shadow:{
      type: Boolean,
      default: false
    },
    tagIndicator:{
      type:String,
      default:''
    },
    counterIndicator:{
      type:Number,
      default:0
    },
    badgeIndicator:{
      type:Boolean,
      default:false
    },
  },
  methods:{
    buttonClick(){
      if(!this.disabled && !this.isLoading){
        this.$emit('click-element')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.default-btn{
  width: 100%;
  border-radius: 8px;
  border: 0;
  outline: none;
  padding: 8px 12px; //medium
  background-color: var(--colorBackgroundAccent);
  transition: all 0.2s ease;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  .btn-main-text{
    text-align: center;
    color: var(--colorTextContrast);

    font-size: 14px; //medium
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;

    padding: 1px 4px;
  }
  .loader {
    padding: 2px;
  }
  .left-icon-btn, .right-icon-btn{
    .left-icon-btn__container, .right-icon-btn__container{
      width: 20px; //medium
      height: 20px; //medium
      background-color: var(--colorIconContrast);
    }
  }
  &.minimal-btn{
    padding: 8px;
  }
  &.shadow-btn{
    box-shadow: 0 4px 8px 0 rgba(23, 23, 28, 0.05), 0 0 4px 0 rgba(23, 23, 28, 0.10);
  }
  //default-appearance:
  &.primary{
    background-color: var(--colorBackgroundAccent);
    .btn-main-text{
      color: var(--colorTextContrast);
    }
    .left-icon-btn, .right-icon-btn{
      .left-icon-btn__container, .right-icon-btn__container{
        background-color: var(--colorIconContrast);
      }
    }
    &:not(.disabled):hover{
      background-color: var(--colorBackgroundAccentHover);
    }
    &.active{
      background-color: var(--colorBackgroundAccentActive);
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundAccentHover);
      }
    }
  }
  &.secondary {
    background-color: var(--colorBackgroundSecondary);
    .btn-main-text{
      color: var(--colorTextAccent);
    }
    .left-icon-btn, .right-icon-btn{
      .left-icon-btn__container, .right-icon-btn__container{
        background-color: var(--colorIconAccent);
      }
    }
    &:not(.disabled):hover{
      background-color: var(--colorBackgroundSecondaryHover);
    }
    &.active{
      background-color: var(--colorBackgroundSecondaryActive);
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundSecondaryHover);
      }
    }
  }
  &.tertiary {
    background-color: var(--colorBackgroundTertiary);
    .btn-main-text{
      color: var(--colorTextAccent);
    }
    .left-icon-btn, .right-icon-btn{
      .left-icon-btn__container, .right-icon-btn__container{
        background-color: var(--colorIconAccent);
      }
    }
    &:not(.disabled):hover{
      background-color: var(--colorBackgroundTertiaryHover);
    }
    &.active{
      background-color: var(--colorBackgroundTertiaryActive);
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundTertiaryHover);
      }
    }
  }
  &.outline {
    border: 1px solid var(--colorStrokeAccent);
    background-color: var(--colorBackgroundTertiary);
    .btn-main-text{
      color: var(--colorTextAccent);
    }
    .left-icon-btn, .right-icon-btn{
      .left-icon-btn__container, .right-icon-btn__container{
        background-color: var(--colorIconAccent);
      }
    }
    &:not(.disabled):hover{
      background-color: var(--colorBackgroundTertiaryHover);
    }
    &.active{
      background-color: var(--colorBackgroundTertiaryActive);
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundTertiaryHover);
      }
    }
  }


  &.negative {
    &.primary{
      background-color: var(--colorBackgroundNegative);
      .btn-main-text{
        color: var(--colorTextContrastInvariably);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconContrastInvariably);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundNegativeHover);
      }
      &.active{
        background-color: var(--colorBackgroundNegativeActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundNegativeHover);
        }
      }
    }
    &.secondary {
      background-color: var(--colorBackgroundSecondary);
      .btn-main-text{
        color: var(--colorTextNegative);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconNegative);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundSecondaryHover);
      }
      &.active{
        background-color: var(--colorBackgroundSecondaryActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundSecondaryHover);
        }
      }
    }
    &.tertiary {
      background-color: var(--colorBackgroundTertiary);
      .btn-main-text{
        color: var(--colorTextNegative);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconNegative);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundTertiaryHover);
      }
      &.active{
        background-color: var(--colorBackgroundTertiaryActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundTertiaryHover);
        }
      }
    }
    &.outline {
      border: 1px solid var(--colorStrokeNegative);
      background-color: var(--colorBackgroundTertiary);
      .btn-main-text{
        color: var(--colorTextNegative);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconNegative);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundTertiaryHover);
      }
      &.active{
        background-color: var(--colorBackgroundTertiaryActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundTertiaryHover);
        }
      }
    }
  }

  &.neutral {
    &.primary{
      background-color: var(--colorBackgroundContrast);
      .btn-main-text{
        color: var(--colorTextContrast);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconContrast);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundContrastHover);
      }
      &.active{
        background-color: var(--colorBackgroundContrastActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundContrastHover);
        }
      }
    }
    &.secondary {
      background-color: var(--colorBackgroundSecondary);
      .btn-main-text{
        color: var(--colorTextPrimary);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconPrimary);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundSecondaryHover);
      }
      &.active{
        background-color: var(--colorBackgroundSecondaryActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundSecondaryHover);
        }
      }
    }
    &.tertiary {
      background-color: var(--colorBackgroundTertiary);
      .btn-main-text{
        color: var(--colorTextPrimary);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconPrimary);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundTertiaryHover);
      }
      &.active{
        background-color: var(--colorBackgroundTertiaryActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundTertiaryHover);
        }
      }
    }
    &.outline {
      border: 1px solid var(--colorStrokeContrast);
      background-color: var(--colorBackgroundTertiary);
      .btn-main-text{
        color: var(--colorTextPrimary);
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          background-color: var(--colorIconPrimary);
        }
      }
      &:not(.disabled):hover{
        background-color: var(--colorBackgroundTertiaryHover);
      }
      &.active{
        background-color: var(--colorBackgroundTertiaryActive);
        &:not(.disabled):hover{
          background-color: var(--colorBackgroundTertiaryHover);
        }
      }
    }
  }

  &.disabled{
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.large{
    padding: 12px 20px;
    .btn-main-text{
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.024px;

      padding: 2px 4px;
    }
    .loader {
      padding: 4px;
    }
    .left-icon-btn, .right-icon-btn{
      .left-icon-btn__container, .right-icon-btn__container{
        width: 24px;
        height: 24px;
      }
    }
    &.minimal-btn{
      padding: 12px;
    }
  }
  &.medium{
    padding: 8px 12px;
    .btn-main-text{
      font-size: 14px;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.07px;

      padding: 1px 4px;
    }
    .loader {
      padding: 2px;
    }
    .left-icon-btn, .right-icon-btn{
      .left-icon-btn__container, .right-icon-btn__container{
        width: 20px;
        height: 20px;
      }
    }
    &.minimal-btn{
      padding: 8px;
    }
  }
  &.small{
    padding: 6px 12px;
    .btn-main-text{
      font-size: 12px;
      font-weight: 500;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.03px;

      padding: 1px 2px;
    }
    .loader {
      padding: 2px;
    }
    .left-icon-btn, .right-icon-btn{
      .left-icon-btn__container, .right-icon-btn__container{
        width: 16px;
        height: 16px;
      }
    }
    &.minimal-btn{
      padding: 6px;
    }
  }

  &.default-size{
    @media screen and (max-width: 1365px){
      padding: 12px 20px;
      .btn-main-text{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.024px;
        padding: 2px 4px;
      }
      .loader {
        padding: 4px;
      }
      .left-icon-btn, .right-icon-btn{
        .left-icon-btn__container, .right-icon-btn__container{
          width: 24px;
          height: 24px;
        }
      }
      &.minimal-btn{
        padding: 12px;
      }
    }
  }
}
</style>