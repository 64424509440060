import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import AboutEditorProject from "@/views/Pages/ProjectManagement/EditorProject/AboutEditorProject.vue";
import structureEditorProject from "@/views/Pages/ProjectManagement/EditorProject/StructureEditorProject.vue";
import discountsEditorProject from "@/views/Pages/ProjectManagement/EditorProject/DiscountsEditorProject.vue";
import markupsEditorProject from "@/views/Pages/ProjectManagement/EditorProject/MarkupsEditorProject.vue";
import repairsEditorProject from "@/views/Pages/ProjectManagement/EditorProject/RepairsEditorProject.vue";
import additionalPackagesEditorProject
    from "@/views/Pages/ProjectManagement/EditorProject/AdditionalPackagesEditorProject.vue";
import feedsEditorProject from "@/views/Pages/ProjectManagement/EditorProject/FeedsEditorProject.vue";
import presentationEditorProject from "@/views/Pages/ProjectManagement/EditorProject/PresentationEditorProject.vue";
import videoEditorProject from "@/views/Pages/ProjectManagement/EditorProject/VideoEditorProject.vue";
import objectSettingsEditorProject from "@/views/Pages/ProjectManagement/EditorProject/ObjectSettingsEditorProject.vue";
import MapEditorProject from "@/views/Pages/ProjectManagement/EditorProject/MapEditorProject.vue";
import StockLayout from "@/views/CalculatorSettings/Layouts/StockLayout.vue";
import ProjectsLayout from "@/views/CalculatorSettings/Layouts/ProjectsLayout.vue";
import BankingProgramsLayout from "@/views/CalculatorSettings/Layouts/BankingProgramsLayout.vue";
import MarkupsLayout from "@/views/CalculatorSettings/Layouts/MarkupsLayout.vue";
import RepairsLayout from "@/views/CalculatorSettings/Layouts/RepairsLayout.vue";

Vue.use(VueRouter)
Array.prototype.unique = function () {
    let a = this.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};
const ifAuth = (to, from, next) => {
    let role = store.getters.getRole

    //насколько тут нужна эта проверка, если тянется с геттера
    if (!role) {
        role = localStorage.getItem('myRole') ?? 'unknown'
    }

    if (role == 'unknown') {
        if (to.query) {
            let utm = new URLSearchParams(to.query)
            next('/auth?' + utm.toString())
            return
        }

        next('/auth')
        return
    }

    next()
}

const routes = [
    //Главная:
    {
        path: '/',
        name: 'main',
        component: () => import('../views/MainView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'news'
        },
    },

    //Сделки:
    {
        path: '/deals',
        name: 'deals',
        component: () => import('../views/LeadsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'work_fill',
            label: 'Сделки',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'clients'
        },
    },
    {
        path: '/deals-agent/:respId',
        name: 'deals-agent',
        component: () => import('../views/LeadsView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
        props: true
    },
    {
        path: '/deals-it',
        name: 'deals-it',
        component: () => import('../views/LeadsView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/deals/create',
        name: 'createDeal',
        component: () => import('../views/LeadNewView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/deals/new',
        name: 'createDeal',
        component: () => import('../views/LeadNewView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/deals/new/:id',
        name: 'deal',
        component: () => import('../views/LeadView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/deals/:id',
        name: 'deal',
        component: () => import('../views/LeadView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/deal/:id',
        name: 'iframe',
        component: () => import('../views/LeadView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/testing-leads',
        name: 'testing-leads',
        component: () => import('../views/TestLeadsView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },

    //Каталог:
    {
        path: '/catalog',
        name: 'catalog',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'view_chess+',
            label: 'Каталог',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'objects'
        },
    },
    {
        path: '/catalog/:subtype',
        name: 'catalog',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        }
    },
    {
        path: '/catalog/:subtype/:project',
        name: 'catalog',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
            breadCrumbs: {
                name: 'Проект'
            }
        }
    },
    {
        path: '/catalog/:subtype/:project/:liter',
        name: 'catalog',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
            breadCrumbs: {
                name: 'Строение'
            }
        },
    },
    {
        path: '/catalog/:subtype/:project/:liter/:floor',
        name: 'catalog',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
            breadCrumbs: {
                name: 'Этаж'
            }
        }
    },

    {
        path: '/chess',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-current',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-current/:city',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-current/:city/:complex',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-current/:city/:complex/:liter',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-amo/:amo',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess/:deal',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess/:deal/:crm',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess/:deal/:crm/:type',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
        beforeEnter: (to, from, next) => {
            //костыль, пока не будет заменен роут внутри виджета амо срм
            const crms = ['bitrix', 'amo']

            if (!crms.includes(to.params?.crm)) {
                next(`/chess/${to.params.deal}/${to.params?.type}/${to.params?.crm}`);
            } else {
                next();
            }
        },
    },
    {
        path: '/chess/:deal/:crm/:type/:object',
        name: 'chess',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-subtype/:subtype',
        name: 'chess-crm',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-subtype/:subtype/:project',
        name: 'chess-crm',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-subtype/:subtype/:project/:liter',
        name: 'chess-crm',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chess-subtype/:subtype/:project/:liter/:floor',
        name: 'chess-crm',
        component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },

    //Управление:
    { //убрать в будущем этот роут
        path: '/stocks',
        name: 'stocks',
        component: () => import('../views/StocksView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'promo',
            label: 'Акции',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/stocks/:id',
        name: 'stocks',
        component: () => import('../views/StockView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/ambassadors',
        name: 'ambassadors',
        component: () => import('../views/AmbassadorsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'record_voice_over',
            label: 'Амбассадоры',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    { //убрать в будущем этот роут
        path: '/banks',
        name: 'banks',
        component: () => import('../views/BanksView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'bank',
            label: 'БанкиМеню',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/banks/:id',
        name: 'bank',
        component: () => import('../views/BankView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/banks/:id/programs/:program',
        name: 'program',
        component: () => import('../views/ProgramView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/banners',
        name: 'banners',
        component: () => import('../views/BanerView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'banners',
            label: 'Баннеры',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/banner/:id',
        name: 'banner',
        component: () => import('../views/BannerView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/calculator-settings',
        name: 'calculatorPanel',
        component: () => import('@/views/CalculatorSettings/WrapperCalculator.vue'),
        redirect: '/calculator-settings/banking-programs',
        beforeEnter: ifAuth,
        meta: {
            icon: 'calculate',
            label: 'Калькулятор',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
        children: [
            {
                path: 'banking-programs',
                name: 'banking-programs',
                component: BankingProgramsLayout,
                props: {label: 'БанковскиеПрограммы'},
            },
            {path: 'projects', name: 'projects', component: ProjectsLayout, props: {label: 'Проекты'}},
            {path: 'stock', name: 'stock', component: StockLayout, props: {label: 'Акции'}},
            {path: 'markups', name: 'markups', component: MarkupsLayout, props: {label: 'Наценки'}},
            {path: 'repairs', name: 'repairs', component: RepairsLayout, props: {label: 'Ремонты'}}
        ],
    },
    {
        path: '/create-repair',
        name: 'create-repair',
        props: {label: 'ДобавлениеРемонта'},
        component: () => import('../views/CalculatorSettings/SaveRepairs.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/edit-repair/:id',
        name: 'edit-repair',
        props: {label: 'РедактированиеРемонта'},
        component: () => import('../views/CalculatorSettings/SaveRepairs.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/create-markups',
        name: 'create-markups',
        props: {label: 'ДобавлениеНаценки'},
        component: () => import('../views/CalculatorSettings/SaveMarkups.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/edit-markups/:id',
        name: 'edit-markups',
        props: {label: 'РедактированиеНаценки'},
        component: () => import('../views/CalculatorSettings/SaveMarkups.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/create-program',
        name: 'create-program',
        props: {label: 'ДобавлениеПрограммы'},
        component: () => import('../views/CalculatorSettings/SavePrograms.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/edit-program/:id',
        name: 'edit-program',
        props: {label: 'ИзменениеПрограммы'},
        component: () => import('../views/CalculatorSettings/SavePrograms.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/create-stock',
        name: 'create-stock',
        props: {label: 'ДобавлениеАкции'},
        component: () => import('../views/CalculatorSettings/SaveStock.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/edit-stock/:id',
        name: 'edit-stock',
        props: {label: 'ИзменениеАкции'},
        component: () => import('../views/CalculatorSettings/SaveStock.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    { //убрать в будущем этот роут
        path: '/calculator-programs',
        name: 'calculatorPrograms',
        component: () => import('../views/CalculatorProgramsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'format_align_left',
            label: 'Калькулятор:Программы',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/calculator-programs/:id',
        name: 'calculatorPrograms',
        component: () => import('../views/CalculatorProgramView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/custom-variables',
        name: 'custom-variables',
        component: () => import('../views/CustomVariables.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'variable_add',
            label: 'КастомныеПеременные',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/items',
        name: 'items',
        component: () => import('../views/ShopItemsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'local_mall',
            label: 'Магазин',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/items/:id',
        name: 'item',
        component: () => import('../views/ShopItemView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/actual-events',
        name: 'actual-events',
        component: () => import('../views/EventsListActual.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'calendar',
            label: 'Мероприятия',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/actual-news',
        name: 'actual-news',
        component: () => import('../views/NewsListActual.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'add_media',
            label: 'Новости',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/archive-news',
        name: 'archive-news',
        component: () => import('../views/NewsListArchive.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/future-news',
        name: 'future-news',
        component: () => import('../views/NewsListFuture.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/actual-news/:id',
        name: 'actual-new',
        component: () => import('../views/NewsEditView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/news-answers/:id',
        name: 'news-answers',
        component: () => import('../views/NewsListAnswersView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/reviews/curator',
        name: 'reviews-curator',
        component: () => import('../views/ReviewsCuratorView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'reviews',
            label: 'Отзывы',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/buildings',
        name: 'buildings',
        component: () => import('../views/BuildingsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'layout_project',
            label: 'Проекты',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/buildings/:id',
        name: 'building',
        component: () => import('../views/Pages/ProjectManagement/EditorProject/WrapperEditorProject.vue'),
        redirect: '/buildings/:id/about',
        children: [
            {path: 'about', name: 'about', component: AboutEditorProject, props: {label: 'Описание'}},
            {path: 'objects', name: 'objects', component: structureEditorProject, props: {label: 'СписокОбъектов'}},
            {path: 'discounts', name: 'discounts', component: discountsEditorProject, props: {label: 'Скидки'}},
            {path: 'markups', name: 'markups', component: markupsEditorProject, props: {label: 'Наценки'}},
            {path: 'repairs', name: 'repairs', component: repairsEditorProject, props: {label: 'Ремонты'}},
            {
                path: 'additional-packages',
                name: 'additional-packages',
                component: additionalPackagesEditorProject,
                props: {label: 'ДопПакеты'}
            },
            {path: 'feeds', name: 'feeds', component: feedsEditorProject, props: {label: 'Фиды'}},
            {
                path: 'presentation',
                name: 'presentation',
                component: presentationEditorProject,
                props: {label: 'Презентация'}
            },
            {path: 'video', name: 'video', component: videoEditorProject, props: {label: 'Видеосторис'}},
            {path: 'edit-map', name: 'edit-map', component: MapEditorProject, props: {label: 'РедакторКарты'}},
            {
                path: 'object-settings',
                name: 'object-settings',
                component: objectSettingsEditorProject,
                props: {label: 'НастройкаОбъекта'}
            },
        ],
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/buildings/:id/:tab',
        name: 'building',
        component: () => import('../views/Pages/ProjectManagement/EditorProject/WrapperEditorProject.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/building-characteristics/:id/:type',
        name: 'building',
        component: () => import('../views/Pages/ProjectManagement/EditorProject/ObjectCharacteristicsEditorProject.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/building-structure/:complex/:id',
        name: 'building',
        component: () => import('../views/BuildView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/bloc-renovations',
        name: 'bloc-renovations',
        component: () => import('../views/BLOC/BlocRenovationsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'roller',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            label: 'РемонтыBLOC',
            category: 'manage'
        },
    },
    {
        path: '/bloc-renovations/edit/:id?',
        name: 'bloc-renovations',
        component: () => import('../views/BLOC/BlocRenovationEditView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/bloc-renovations/edit-furniture/:id?',
        name: 'bloc-renovations',
        component: () => import('../views/BLOC/blocFurnitureEditView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/bloc-renovations/edit-discount/:id?',
        name: 'bloc-renovations',
        component: () => import('../views/BLOC/blocDiscountEditView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/bloc-renovations/edit-package/:id?',
        name: 'bloc-renovations',
        component: () => import('../views/BLOC/blocPackageEditView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/bloc-renovations/edit-color/:id?',
        name: 'bloc-renovations',
        component: () => import('../views/BLOC/blocColorEditView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/stats',
        name: 'stats',
        component: () => import('../views/StatsMainView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'layout_align_vertical_bottom',
            label: 'Статистика',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/stories',
        name: 'stories',
        component: () => import('../views/StoriesView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'stories_image',
            label: 'Сторисы',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/feeds',
        name: 'feeds',
        component: () => import('../views/FeedView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'link',
            label: 'Фиды',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/feeds/:id',
        name: 'feeds',
        component: () => import('../views/Rooms/MainRoomsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/board-admin',
        name: 'home-admin',
        component: () => import('../views/BoardAdminView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'view_chess',
            label: 'ШахматкаАдмина',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/organizations',
        name: 'organizations',
        component: () => import('../views/OrganizationsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'deal',
            label: 'ЮрЛица',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'manage'
        },
    },
    {
        path: '/organizations/:id',
        name: 'organization',
        component: () => import('../views/OrganizationView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },

    //Разработчик:
    {
        path: '/subdomains',
        name: 'subdomains',
        component: () => import('../views/SubdomainsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'account_circle',
            label: 'АккаунтыIT',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/subdomains/:id',
        name: 'subdomains',
        component: () => import('../views/SubdomainView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/editor-markups/projects/list',
        name: 'graphic-editor-list-projects',
        component: () => import('../views/Pages/GraphicEditor/ListOfMarkups.vue'),
        meta: {
            icon: 'add_elements',
            label: 'ГрафическийРедактор',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/editor-markups/:markupType/list/:id?',
        name: 'graphic-editor-list',
        component: () => import('../views/Pages/GraphicEditor/ListOfMarkups.vue'),
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    },
    {
        path: '/graphic-editor/editor/:markupType/:id?',
        component: () => import('../views/Pages/GraphicEditor/GraphicEditor.vue'),
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    },
    {
        path: '/update-list',
        name: 'update-list',
        component: () => import('../views/UpdateView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'import',
            label: 'ЗагрузкаДанных',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/integrations',
        name: 'integrations',
        component: () => import('../views/IntegrationsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'lan',
            label: 'Интеграции',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/calculator-code-samples-new',
        name: 'calculator-code-samples-new',
        component: () => import('../views/CalculatorCodeSamplesNewView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'calculate',
            label: 'Калькулятор',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/educations',
        name: 'educations',
        component: () => import('../views/EducationsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'school',
            label: 'Обучение',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/education/:id',
        name: 'education',
        component: () => import('../views/EducationView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/education/:education/:id',
        name: 'question',
        component: () => import('../views/QuestionView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/agent-list',
        name: 'agent-list',
        component: () => import('../views/AgentListView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'groups',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            label: 'Пользователи',
            category: 'dev'
        },
    },
    {
        path: '/statuses-sync',
        name: 'statusesSync',
        component: () => import('../views/StatusesSyncView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'filter_alt',
            label: 'СинхронизацияСтатусов',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/statuses-sync/:id',
        name: 'statusesSync',
        component: () => import('../views/StatusSyncView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/statuses',
        name: 'statuses',
        component: () => import('../views/StatusesView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'filter_alt',
            label: 'СтатусыВоронки',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/statuses/:id',
        name: 'status',
        component: () => import('../views/StatusView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/delete-users',
        name: 'deleteUsers',
        component: () => import('../views/DeleteUsers.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'quiz',
            label: 'Тестирование',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/triggers',
        name: 'triggers',
        component: () => import('../views/TriggersView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'error',
            label: 'Триггеры',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/triggers/:id',
        name: 'triggers',
        component: () => import('../views/TriggerView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },

    //Дополнительные страницы в меню
    {
        path: '/ambassador',
        name: 'ambassador',
        component: () => import('../views/ambassadorView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'record_voice_over',
            label: 'Амбассадор',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },
    {
        path: '/recent',
        name: 'Heart',
        component: () => import('../views/RecentView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'favorite',
            label: 'Избранное',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },
    {
        path: '/shop',
        name: 'shop',
        component: () => import('../views/ShopView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'local_mall',
            label: 'Магазин',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },

    {
        path: '/events',
        name: 'events',
        component: () => import('../views/EventsView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'calendar',
            label: 'Мероприятия',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },
    {
        path: '/events/:id',
        name: 'events',
        component: () => import('../views/EventView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/event-card/:id',
        name: 'event-card',
        component: () => import('../views/EventCardView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/archive-events',
        name: 'archive-events',
        component: () => import('../views/EventsListArchive.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/actual-events/:id',
        name: 'actual-events',
        component: () => import('../views/EventsEditView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/actual-events-new',
        name: 'actual-events-new',
        component: () => import('../views/EventsNewView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },

    {
        path: '/train',
        name: 'StudentTest',
        component: () => import('../views/StudentMainView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'school',
            label: 'Обучение',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },
    {
        path: '/backlog',
        name: 'backlog',
        component: () => import('../views/BacklogView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'reviews',
            label: 'ОтзывыОПриложении',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },
    {
        path: '/selection',
        name: 'Bookmarks',
        component: () => import('../views/BookmarksView'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'collection',
            label: 'Подборки',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },
    {
        path: '/selection/:id',
        name: 'Bookmark',
        component: () => import('../views/BookmarkView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/doc-templates',
        name: 'doc-templates',
        component: () => import('../views/DocTemplatesView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'deal',
            label: 'ШаблоныДокументов',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'additional'
        },
    },


    //Профиль:
    {
        path: '/profile/:tab',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/profile/groups/:groupId',
        name: 'profile',
        component: () => import('../views/Pages/Profile/EditorGroupsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/profile-workers',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WorkersView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/profile-user/:id/:tab',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/profile-user/:id/groups/:groupId',
        name: 'profile',
        component: () => import('../views/Pages/Profile/EditorGroupsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/profile-workers/:id',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WorkersView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/profile-menu/:id',
        name: 'profile',
        component: () => import('../views/ProfileMenuView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },

    //Остальное
    {
        path: '/registration',
        name: 'registration',
        component: () => import('../views/RegistrationView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/gm',
        name: 'GM',
        component: () => import('../views/gm/GMMapView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('../views/TasksView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/brokeridge',
        name: 'brokeridge',
        component: () => import('../views/EstateMarkupView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
        props: {
            markupId: '18',
            isEditorDisable: true
        }
    },
    {
        path: '/parking/:developmentId?',
        name: 'parking_development',
        component: () => import('../views/EstateMarkupView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
        props: (route) => ({
            isEditorDisable: false,
            category: 'parking',
            title: 'Парковки',
            developmentId: route.params.developmentId
        })
    },
    {
        path: '/parking/show/:markupId?',
        name: 'parking_one',
        component: () => import('../views/EstateMarkupView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
        props: (route) => ({
            isEditorDisable: true,
            markupId: route.params.markupId
        })
    },
    {
        path: '/list-rooms',
        name: 'rooms',
        component: () => import('../views/Rooms/ListFlatsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    },
    {
        path: '/create',
        name: 'rooms',
        component: () => import('../views/Rooms/MainRoomsView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/create-object/:id?',
        name: 'rooms',
        component: () => import('../views/Rooms/CreateObjectView.vue'),
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
        props: true
    },
    {
        path: '/my-object',
        name: 'rooms',
        component: () => import('../views/Rooms/CreateObjectView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/pantries',
        name: 'pantries',
        component: () => import('../views/Versions/PantriesVersions.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    },
    {
        path: '/agents',
        name: 'agents',
        component: () => import('../views/AgentsView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    },
    {
        path: '/agentsold',
        name: 'agentsold',
        component: () => import('../views/Agentsold.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/search-agents',
        name: 'searchAgents',
        component: () => import('../views/AgentsSearch.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/banks-table',
        name: 'bankst',
        component: () => import('../views/BanksTableView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/discount-matrix',
        name: 'discount-matrix',
        component: () => import('../views/DiscountMatrixView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'discount_matrix',
            label: 'МатрицаСкидок',
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/requests',
        name: 'requests',
        component: () => import('../views/RequestsView'),
        beforeEnter: ifAuth,
        meta: {
            label: 'ЗапросыМеню',
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/requests/:id',
        name: 'request',
        component: () => import('../views/RequestView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/b24log',
        name: 'b24log',
        component: () => import('../views/BLogView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/360',
        name: '360',
        component: () => import('../views/360.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/agent-deals',
        name: 'agent-deals',
        component: () => import('../views/AgentDealsView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },

    },
    {
        path: '/agent-deal/:id',
        name: 'agent-deal',
        component: () => import('../views/AgentDealItemView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/ambassador-card/:id',
        name: 'ambassador-card',
        component: () => import('../views/ambassadorCurrentView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/vip',
        name: 'vip',
        component: () => import('../views/VIP/HeaderView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/vip/:subtype',
        name: 'vip',
        component: () => import('../views/VIP/HeaderView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/object/:id',
        name: 'object',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/small-object/:id',
        name: 'object',
        component: () => import('../views/SmallObject'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/object-ext/:extid/:element',
        name: 'object',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/object/:id/:element',
        name: 'object',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/object-ext/:extid',
        name: 'object',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/object-trk/:id',
        name: 'object-trk',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/objects/:id',
        name: 'object',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/objects/:id/:deal',
        name: 'object',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },

    {
        path: '/assistant',
        name: 'assistant',
        component: () => import('../views/VoiceRecognitionView.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/chats',
        name: 'chats',
        component: () => import('../views/ChatsView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/visit/:hash',
        name: 'visit',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectsView.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/attend/:hash',
        name: 'attend',
        component: () => import('../views/Pages/ObjectCard/WrapperObjectsView.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/business-card/:hash',
        name: 'business-card',
        component: () => import('../views/CardBusinessSmallView.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/certificate/:hash',
        name: 'certificate',
        component: () => import('../views/CertificatePDFView.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/trk/:hash',
        name: 'trk',
        component: () => import('../views/TrkPDFView.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },

    {
        path: '/calc',
        name: 'calc',
        component: () => import('../components/redesign/calculator.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/calc/:id',
        name: 'calc',
        component: () => import('../components/redesign/calculator.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/calc-bravo',
        name: 'calc-bravo',
        component: () => import('../components/redesign/calculator.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/calc-bravo/:id',
        name: 'calc-bravo',
        component: () => import('../components/redesign/calculator.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },

    {
        path: '/install-widget/:domain',
        name: 'install-widget',
        component: () => import('../views/Pages/ExpressSetup/ExpressSetup.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },

    {
        path: '/reservation-sber/:hash', //Костыли для Сбера
        name: 'reservation-sber',
        component: () => import('../views/Pages/Reservation/ReservationView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/reservation-sber',
        name: 'reservation-sber',
        component: () => import('../views/Pages/Reservation/ReservationView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/reservation-bravo/:hash', //Костыли для Браво
        name: 'reservation-bravo',
        component: () => import('../views/Pages/Reservation/ReservationView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/reservation-bravo',
        name: 'reservation-bravo',
        component: () => import('../views/Pages/Reservation/ReservationView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/reservation/:hash',
        name: 'reservation',
        component: () => import('../views/Pages/Reservation/ReservationView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/reservation',
        name: 'reservation',
        component: () => import('../views/Pages/Reservation/ReservationView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },

    {
        path: '/mini-pdf/:hash',
        name: 'mini-pdf',
        component: () => import('../views/DefaultMiniPDFView.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/default-pdf/:hash',
        name: 'default-pdf',
        component: () => import('../views/Pages/PDF/PDFType.vue'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },

    {
        path: '/start',
        name: 'auth',
        component: () => import('../views/StartView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/entrance',
        name: 'auth',
        component: () => import('../views/AuthView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/invite/:hash',
        name: 'auth',
        component: () => import('../views/LoginView'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/partner/create',
        name: 'auth',
        component: () => import('../views/auth'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/partner',
        name: 'auth',
        component: () => import('../views/auth'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/partner/:hash',
        name: 'partnerLink',
        component: () => import('../views/PartnerLinkView'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/ambassador/:hash',
        name: 'auth',
        component: () => import('../views/LoginView'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/curator/:hash',
        name: 'auth',
        component: () => import('../views/LoginView'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/cashback/:hash',
        name: 'cashback',
        component: () => import('../views/cashbackLink'),
        meta: {
            needAuth: false,
            hideAll: false,
            hideBar: true
        },
    },
    {
        path: '/client/:data',
        name: 'auth',
        component: () => import('../views/autoAuth'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/client/:data/:lead',
        name: 'auth',
        component: () => import('../views/autoAuth'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/expo',
        name: 'auth',
        component: () => import('../views/LendingView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },

    },
    {
        path: '/statuspage',
        name: 'auth',
        component: () => import('../views/StatusPageView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/legacy',
        name: 'auth',
        component: () => import('../views/PrivacyPolicyView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/security',
        name: 'auth',
        component: () => import('../views/SecurityView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/privacy-personal-data',
        name: 'auth',
        component: () => import('../views/PersonalDataProcessingView.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/pay-info',
        name: 'auth',
        component: () => import('../views/PayInfo.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/auth',
        name: 'auth',
        // component: () => import('../views/LendingView.vue')
        component: () => import('../views/LoginView'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
        // component: () => import('../views/CompetitionView')
        // component: () => import('../views/auth')
    },

    {
        path: '/calculator/:sum/:square',
        name: 'calculator',
        component: () => import('../views/calculator'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/calculator/:sum',
        name: 'calculator',
        component: () => import('../views/calculator'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },
    {
        path: '/calculator',
        name: 'calculator',
        component: () => import('../views/calculator'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: false
        },
    },

    {
        path: '/buys/:id',
        name: 'buy',
        component: () => import('../views/BuyView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/buys',
        name: 'buys',
        component: () => import('../views/BuysView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/archive-buys',
        name: 'archive-buys',
        component: () => import('../views/BuysArchiveView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/markup/:id',
        name: 'markup',
        component: () => import('../views/MarkupView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/discount/:id',
        name: 'discount',
        component: () => import('../views/DiscountView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/repair/:id',
        name: 'markup',
        component: () => import('../views/RepairView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/packet/:id',
        name: 'markup',
        component: () => import('../views/PacketView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/profit/:id',
        name: 'profit',
        component: () => import('../views/ProfitView'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/video-card/:hash',
        name: 'video-card',
        component: () => import('../components/videoComponent.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/estate-markup-editor/:id',
        name: 'estate-markup-editor',
        component: () => import('../views/EstateMarkupEditorView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/doc-editor/:id',
        name: 'generated-docs-editor',
        component: () => import('../views/GeneratedDocsEditorView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    },
    {
        path: '/test-push',
        name: 'test-push',
        component: () => import('../views/TestPushView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    },
    {
        path: '/check-history',
        name: 'check-history',
        component: () => import('../views/CheckHistoryView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    },
    {
        path: '/avto-mall',
        name: 'avto-mall',
        component: () => import('../views/Pages/AvtoMall/AvtoMall.vue'),
        meta: {
            needAuth: false,
            hideAll: true,
            hideBar: true
        },
    },
    {
        path: '/swagger',
        name: 'swagger',
        component: () => import('../views/SwaggerView.vue'),
        beforeEnter: ifAuth,
        meta: {
            label: 'Swagger',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/deals2',
        name: 'deals2',
        component: () => import('../views/LeadsNewView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'deals',
            label: 'СделкиМеню2',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'clients'
        },
    },
    {
        path: '/swagger',
        name: 'swagger',
        component: () => import('../views/SwaggerView.vue'),
        beforeEnter: ifAuth,
        meta: {
            label: 'Swagger',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'dev'
        },
    },
    {
        path: '/deals2',
        name: 'deals2',
        component: () => import('../views/LeadsNewView.vue'),
        beforeEnter: ifAuth,
        meta: {
            icon: 'deals',
            label: 'СделкиМеню2',
            needAuth: true,
            hideAll: false,
            hideBar: false,
            category: 'clients'
        },
    }
]
if (window.innerWidth > 1090) { //вынужденный костыль... Его можно убрать, когда нарисуем что-то ещё по ссылке /profile. Так как сейчас на компах слишком пусто на /profile и приходится делать редирект на первый таб.
    routes.push({
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
        redirect: '/profile/personal',
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    })
    routes.push({
        path: '/profile-user/:id',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
        redirect: '/profile-user/:id/personal',
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    })
} else {
    routes.push({
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false,
        },
    })
    routes.push({
        path: '/profile-user/:id',
        name: 'profile',
        component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
        beforeEnter: ifAuth,
        meta: {
            needAuth: true,
            hideAll: false,
            hideBar: false
        },
    })
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
