<script>
import DefaultButton from "@/components/redesign/defaultButton.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";

export default {
  name: "modalCard",
  components: {IconComponent, DefaultButton},
  props: {
    title: {
      type: String|null
    },
    description: {
      type: String|null
    },
    visibleClose: {
      type: Boolean,
      default: true
    },
    visibleReturn: {
      type: Boolean,
      default: false
    },
    background: {
      type: String|null
    },
  }
}
</script>

<template>
    <div class="modal-card-wrapper" @click.self="$emit('close')" :style="{'background': background}">
      <div class="modal-card">
        <div class="modal-card-profile">
          <div class="modal-card-header" v-if="$slots?.image||visibleReturn||visibleClose">
            <div class="modal-header-icon">
              <icon-component
                  v-if="visibleReturn"
                  :icon="'/default-icons/arrow_back.svg'"
                  :color="'var(--colorIconAccent)'"
                  :size="24"
                  :clickability="true"
                  @click-element="$emit('return')"
              />
            </div>
            <div>
              <slot name="image"/>
            </div>
            <div class="modal-header-icon">
              <icon-component
                  v-if="visibleClose"
                  :icon="'/default-icons/close.svg'"
                  :color="'var(--colorIconAccent)'"
                  :size="24"
                  :clickability="true"
                  @click-element="$emit('close')"
              />
            </div>
          </div>
          <div class="modal-card-description" v-if="title||description">
            <h1 v-if="title">{{title}}</h1>
            <p v-if="description">{{description}}</p>
          </div>
        </div>
        <div class="modal-card-content"  v-if="$slots?.default">
          <slot></slot>
        </div>
        <div class="modal-card-btn" v-if="$slots?.buttons">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">
.modal-card-wrapper {
  padding: 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: var(--colorOverlay);
  display: flex;
  overflow:auto;
  .modal-card {
    box-shadow: 0 16px 32px 0 rgba(23, 23, 28, 0.10), 0 0 16px 0 rgba(23, 23, 28, 0.15);
    height: fit-content;
    background: var(--colorBackgroundContent);
    border-radius:12px;
    padding: 16px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width:304px;
    .modal-card-profile{
      display: flex;
      flex-direction: column;
      gap: 12px;
      .modal-card-header{
        display: flex;
        justify-content: space-between;
        .modal-header-icon{
          width: 24px;
        }
      }
      .modal-card-description {
        align-items: center;
        gap: 4px;
        display: flex;
        flex-direction: column;
        text-align: center;
        > h1 {
          font-size: 20px;
          font-weight: 500;
          color: var(--colorTextPrimary)
        }
        > p {
          color: var(--colorTextSecondary);
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
        }
        > h1, p, a {
          margin: 0;
          word-break: normal;
        }
      }
    }
    .modal-card-content{
      padding-top: 24px;
    }
    .modal-card-btn {
      padding-top: 24px;

      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  @media screen and (width <= 744px) {
    align-items: end;
    .modal-card {
      width: 100% !important;
      margin: auto auto 0;
    }
  }
}
@keyframes modal-card {
  from {
    transform: translateY(500px);
  }
  to {
    transform: translateY(0);
  }
}

</style>