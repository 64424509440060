<template>
  <div class="graphic-editor-select" @click="isActive = !isActive" :class="{'active': isActive}">
    <div class="graphic-editor-select-icon" v-if="color || image || icon" :style="style"></div>
    <div class="graphic-editor-select-label" v-if="!multiple || !items.length">
      {{ items[0] ?? $t('НеУказано')}}
    </div>
    <div class="graphic-editor-select-labels" v-else>
      <div class="graphic-editor-select-labels-item" v-for="item in items" :key="item">
        {{ item }}
      </div>
    </div>
    <div class="graphic-editor-select-expand" :class="{'active': isActive}"></div>
  </div>
</template>

<script>
export default {
  name: "graphicEditorSelect",
  data() {
    return {
      isActive: false
    }
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    color: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      if (this.image) {
        return {background: 'url(' + this.image + ')'}
      }

      if (this.icon) {
        return {
          mask: 'url(' + this.icon + ') no-repeat center / contain',
          backgroundColor: 'var(--colorIconSecondary)'
        }
      }

      return {background: this.color}
    }
  },
  watch: {
    active(val) {
      this.isActive = val
    }
  }
}
</script>

<style scoped lang="scss">
.graphic-editor-select {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  color: var(--colorTextPrimary);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035px;
  border-radius: 8px;
  border: 0.5px solid var(--colorComponentFieldStroke);
  background: var(--colorComponentFieldBackground);
  cursor: pointer;
  transition: all 0.2s ease;

  &.active {
    border: 0.5px solid var(--colorIconAccentInvariably);
    background: var(--colorComponentFieldBackgroundActive);
  }

  .graphic-editor-select-label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  .graphic-editor-select-labels {
    width: 100%;
    display: flex;
    gap: 4px 4px;
    flex-wrap: wrap;

    .graphic-editor-select-labels-item {
      padding: 7px 8px;
      border-radius: 6px;
      border: 0.5px solid var(--colorStrokePrimary);
      background: var(--colorBackgroundContent);
      color: var(--colorTextPrimary);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.035px;
    }
  }

  .graphic-editor-select-icon, .graphic-editor-select-expand {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
  }

  .graphic-editor-select-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }

  .graphic-editor-select-expand {
    mask: url('/public/default-icons/expand_more.svg') no-repeat center / contain;
    background-color: var(--colorIconSecondary);
    transition: transform 0.2s ease;

    &.active {
      transform: rotateX(180deg);
    }
  }
}
</style>