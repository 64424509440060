export const NAVIGATION_CATEGORIES = [
    {
        slug: 'news',
        label: 'Главная',
        iconSrc: '/default-icons/home_fill.svg',
        path: '/'
    },
    {
        slug: 'clients',
        label: 'Сделки',
        iconSrc: '/default-icons/work_fill.svg'
    },
    {
        slug: 'objects',
        label: 'Объекты',
        iconSrc: '/default-icons/view_chess+.svg'
    },
    {
        slug: 'manage',
        label: 'Управление',
        iconSrc: '/default-icons/settings_fill.svg'
    },
    {
        slug: 'dev',
        label: 'Разработчик',
        iconSrc: '/default-icons/code.svg'
    },
]